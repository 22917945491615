import React from 'react';
import './style.css';

const InputRange = (props) => {
	const { value, min, max } = props;
	const percent = ((value - min) / (max - min)) * 100;
	const background =
		'linear-gradient(to right, #cf2345 0%, #cf2345 ' + percent + '%, #e2e3e5 ' + percent + '%, #e2e3e5 100%)';
	return <input style={{ background: background }} {...props} />;
};

export default InputRange;
