/*!
 * ======================================================================
 * RADIOPLAYER TRACKING SDK - RELEASE BETA 1.0.0
 * ======================================================================
 * This software is developed and maintained by KEBULA SRL.
 *
 * COPYRIGHT NOTICE:
 * ---------------------------------------------------------------------
 * © 2024 KEBULA. All Rights Reserved.
 *
 * DISCLAIMER:
 * This software is provided by KEBULA "as is" and any express or implied
 * warranties, including, but not limited to, the implied warranties of
 * merchantability and fitness for a particular purpose are disclaimed.
 * In no event shall KEBULA be liable for any direct, indirect, incidental,
 * special, exemplary, or consequential damages (including, but not limited
 * to, procurement of substitute goods or services; loss of use, data, or
 * profits; or business interruption) however caused and on any theory of
 * liability, whether in contract, strict liability, or tort (including
 * negligence or otherwise) arising in any way out of the use of this
 * software, even if advised of the possibility of such damage.
 *
 * USAGE:
 * This SDK is intended to be used as part of the RADIOPLAYER software
 * solutions and is subject to the terms and conditions stipulated in the
 * software license agreement provided with the SDK package. Unauthorized
 * copying of this file, via any medium, is strictly prohibited and is a
 * violation of copyright laws.
 *
 * This header must not be removed from the file and must be included in
 * all copies of the relevant files.
 * ======================================================================
 */
import {
	newTracker as t,
	trackPageView as n,
	enableActivityTracking as s,
	disableActivityTracking as i,
	trackSelfDescribingEvent as o,
} from '@snowplow/browser-tracker';
import { GeolocationPlugin as r, enableGeolocationContext as a } from '@snowplow/browser-plugin-geolocation';
class u {
	constructor(i, e, t, n, s, o, r, a, c) {
		(this.name = i),
			(this.listening_session_id = e),
			(this.media_id = t),
			(this.media_type = n),
			(this.position = s),
			(this.origin = o),
			(this.output = r),
			(this.sync_info = a),
			(this.feature_origin = c);
	}
	toString() {
		return `ListeningEvent {
        name: ${this.name},
        listening_session_id: ${this.listening_session_id},
        media_id: ${this.media_id},
        media_type: ${this.media_type},
        position: ${this.position},
        origin: ${this.origin},
        output: ${this.output},
        sync_info: ${this.sync_info},
        feature_origin: ${this.feature_origin},
    }`;
	}
}
const c = [
	{ type: 'string', name: 'groupId', enum: !1 },
	{
		type: ['RPWAND', 'RPWIOS', 'RPWGCR', 'RPWATM', 'RPWTZN', 'RPWWOS', 'RPWSKQ', 'RPWFTV', 'RPWTLN', 'RPWATV'],
		name: 'previouslyDevicePlatform',
		enum: !0,
	},
	{ type: 'boolean', name: 'currentlyPlayed', enum: !1 },
];
class g {
	constructor(i) {
		(this.tracker = i),
			(this.listening_session_id = null),
			(this.media_id = null),
			(this.media_type = null),
			(this.position = null),
			(this.origin = null),
			(this.output = null),
			(this.sync_info = null),
			(this.isActive = !1);
	}
	async generateId() {
		var i = Date.now().toString() + Math.random().toString(36).substr(2, 9),
			i = new TextEncoder().encode(i),
			i = await window.crypto.subtle.digest('SHA-256', i);
		return Array.from(new Uint8Array(i))
			.map((i) => i.toString(16))
			.join('');
	}
	_checkSyncInfo(i) {
		var e = [];
		if (i)
			if ('object' != typeof i) e.push('Listening sync_info parameter must be an object');
			else {
				for (const n of c)
					i.hasOwnProperty(n.name) || e.push(n.name + ' is required for Listening sync_info parameter');
				for (const s in i) {
					var t = c.find((i) => i.name === s);
					t
						? t.enum
							? t.type.includes(i[s]) ||
								e.push(
									'Field ' +
										s +
										' must be included in [ ' +
										t.type.join(', ') +
										' ] for Listening sync_info parameter'
								)
							: typeof i[s] !== t.type &&
								e.push('Field ' + s + ' must be a ' + t.type + ' for Listening sync_info parameter')
						: e.push('Field ' + s + 'does not exist for Listening sync_info parameter');
				}
			}
		return e;
	}
	start(i, e, t, n, s, o, r, a) {
		this.isActive && console.warn('Cannot start a new session without stopping the current one.'),
			(this.media_id = e),
			(this.media_type = t),
			(this.position = n),
			(this.origin = s),
			(this.output = o),
			(this.sync_info = null);
		e = this._checkSyncInfo(r);
		if (0 < e.length) for (const c of e) console.warn(c);
		else this.sync_info = r ? JSON.stringify(r) : null;
		t = new u(
			i,
			this.listening_session_id,
			this.media_id,
			this.media_type,
			this.position,
			this.origin,
			this.output,
			this.sync_info,
			a
		);
		this.tracker.track_listening_event(t), (this.isActive = !0);
	}
	stop(i, e, t, n) {
		this.isActive
			? ((i = new u(
					i,
					this.listening_session_id,
					this.media_id,
					this.media_type,
					e,
					this.origin,
					t,
					this.sync_info,
					n
				)),
				this.tracker.track_listening_event(i),
				(this.media_id = null),
				(this.media_type = null),
				(this.position = null),
				(this.origin = null),
				(this.output = null),
				(this.isActive = !1))
			: console.warn('No session is currently active.');
	}
	fire_SEEK_on(i, e, t, n, s, o, r, a) {
		this.isActive
			? ((i = new u(
					i,
					this.listening_session_id,
					this.media_id,
					this.media_type,
					n,
					this.origin,
					o,
					this.sync_info,
					a
				)),
				this.tracker.track_listening_event(i, 'seek', r))
			: console.warn('No session is currently active.');
	}
	fire_SKIP_on(i, e, t, n, s, o, r, a, c) {
		this.isActive
			? ((i = new u(i, this.listening_session_id, e, t, n, this.origin, o, this.sync_info, c)),
				this.tracker.track_listening_event(i, 'skip', {
					index: r,
					skip_type: a,
					previous_media_id: this.media_id,
					previous_media_type: this.media_type,
				}),
				(this.media_id = e),
				(this.media_type = t))
			: console.warn('No session is currently active.');
	}
	fire_PAUSE_on(i, e, t, n, s, o, r) {
		this.isActive
			? ((i = new u(
					i,
					this.listening_session_id,
					this.media_id,
					this.media_type,
					n,
					this.origin,
					o,
					this.sync_info,
					r
				)),
				this.tracker.track_listening_event(i))
			: console.warn('No session is currently active.');
	}
	fire_ERROR_on(i, e, t, n, s, o, r, a) {
		this.isActive
			? ((i = new u(
					i,
					this.listening_session_id,
					this.media_id,
					this.media_type,
					n,
					this.origin,
					o,
					this.sync_info,
					a
				)),
				this.tracker.track_listening_event(i, 'error', r))
			: console.warn('No session is currently active.');
	}
	isListeningSessionActive() {
		return this.isActive;
	}
}
class _ {
	constructor(i, e, t, n, s, o) {
		(this.name = 'error'),
			(this.type = i),
			(this.source = e),
			(this.reason = t),
			(this.origin = n),
			(this.extra_info = s),
			(this.feature_origin = o);
	}
	toString() {
		return `ErrorEvent {
        name: ${this.name},
        type: ${this.type},
        source: ${this.source},
        reason: ${this.reason},
        origin: ${this.origin},
        extra_info: ${this.extra_info},
    }`;
	}
}
class h {
	constructor(i, e, t, n) {
		(this.name = 'set_value'), (this.key = i), (this.value = e), (this.origin = t), (this.feature_origin = n);
	}
	toString() {
		return `ChangeOptionEvent {
         name: ${this.name},
         key: ${this.key},
         value: ${this.value},
         origin: ${this.origin},
         feature_origin: ${this.feature_origin},
    }`;
	}
}
var e = ['RPWAND', 'RPWIOS', 'RPWGCR', 'RPWATM', 'RPWTZN', 'RPWWOS', 'RPWSKQ', 'RPWFTV', 'RPWTLN', 'RPWATV'],
	l = { type: ['STATION', 'SERIES', 'EPISODE'], name: 'mediaType', enum: !0 },
	d = { type: 'string', name: 'mediaId', enum: !1 },
	p = { type: ['ON', 'OFF'], name: 'enable', enum: !0 };
const f = {
	search: [{ type: 'string', name: 'search', enum: !1 }],
	shareRequest: [d, l],
	sleepTimer: [d, l, { type: ['T15', 'T30', 'T45', 'T60', 'T120', 'OFF'], name: 'timerType', enum: !0 }],
	favourite: [d, l, p],
	alarm: [d, l, p, { type: 'string', name: 'dateTimestamp', enum: !1 }],
	download: [d, l],
	siriShortCut: [d, l],
	countryChoice: [],
	countryReset: [],
	appReset: [],
	inAppReview: [],
	sync: [
		p,
		{ name: 'role', type: ['GUEST', 'OWNER'], enum: !0 },
		{ name: 'isMerge', type: 'boolean', enum: !1 },
		{ name: 'numServicesMerged', type: 'number', enum: !1 },
		{ name: 'numSeriesMerged', type: 'number', enum: !1 },
		{ name: 'groupId', type: 'string', enum: !1 },
		{ name: 'guestPlatform', type: e, enum: !0 },
		{ name: 'ownerPlatform', type: e, enum: !0 },
	],
	syncRefuse: [],
	screenView: [
		{ type: 'string', name: 'currentPage', enum: !1 },
		{ type: 'string', name: 'previousPage', enum: !1 },
	],
	changeLayout: [{ type: ['LIST', 'GRID'], name: 'layoutType', enum: !0 }],
};
class m {
	constructor(i, e, t, n, s) {
		(this.name = 'action'),
			(this.type = i),
			(this.subject = e),
			(this.ui_source = t),
			(this.origin = n),
			(this.feature_origin = s);
	}
	checkActionStructure() {
		var i = [];
		if (!f.hasOwnProperty(this.type)) return ["Action type '" + this.type + "' is not supported."];
		for (const t of f[this.type])
			this.subject.hasOwnProperty(t.name) || i.push(t.name + ' is required for ' + this.type + ' Action ');
		for (const n in this.subject) {
			0 === f[this.type].length && i.push('No additional parameter are needed for ' + this.type + ' Action ');
			var e = f[this.type].find((i) => i.name === n);
			e
				? e.enum
					? e.type.includes(this.subject[n]) ||
						i.push(
							'Field ' +
								n +
								' must be included in [ ' +
								e.type.join(', ') +
								' ] for ' +
								this.type +
								' Action, found ' +
								this.subject[n]
						)
					: typeof this.subject[n] !== e.type &&
						i.push(
							'Field ' +
								n +
								' must be a ' +
								e.type +
								' for ' +
								this.type +
								' Action, found ' +
								typeof this.subject[n]
						)
				: i.push('Field ' + n + 'does not exist for ' + this.type + ' Action ');
		}
		return i;
	}
	toString() {
		return `ActionEvent {
        name: ${this.name},
        type: ${this.type},
        subject: ${this.subject},
        ui_source: ${this.ui_source},
        origin: ${this.origin},
        feature_origin ${this.feature_origin},
    }`;
	}
}
class y {
	constructor(i, e, t, n, s) {
		(this.name = i), (this.mirroring_session_id = e), (this.type = t), (this.origin = n), (this.feature_origin = s);
	}
	toString() {
		return `MirroringEvent {
        name: ${this.name},
        mirroring_session_id: ${this.mirroring_session_id},
        type: ${this.type},
        origin: ${this.origin},
        feature_origin: ${this.feature_origin},
    }`;
	}
}
class v {
	constructor(i) {
		(this.tracker = i), (this.type = null), (this.mirroring_session_id = null);
	}
	async generateId() {
		var i = Date.now().toString() + Math.random().toString(36).substr(2, 9),
			i = new TextEncoder().encode(i),
			i = await window.crypto.subtle.digest('SHA-256', i);
		return Array.from(new Uint8Array(i))
			.map((i) => i.toString(16).padStart)
			.join('');
	}
	start(i, e, t, n) {
		this.isActive && console.warn('Cannot start a new mirroring session without stopping the current one.'),
			(this.type = e),
			(this.origin = t);
		e = new y((this.name = i), this.mirroring_session_id, this.type, this.origin, n);
		this.tracker.track_mirroring_event(e), (this.isActive = !0);
	}
	stop(i, e, t, n) {
		this.isActive
			? ((i = new y((this.name = i), this.mirroring_session_id, this.type, this.origin, n)),
				this.tracker.track_mirroring_event(i),
				(this.type = null),
				(this.isActive = !1))
			: console.warn('No mirroring session is currently active.');
	}
	isMirroringSessionActive() {
		return this.isActive;
	}
}
class k {
	static instance = null;
	static schema_sn = 'iglu:Kebula/SDK_general_event_schema_public/jsonschema/1-0-4';
	constructor(i, e) {
		(this.sessionListener = null),
			(this.sessionsMap = new Map()),
			(this.mirroringSession = null),
			(this.isTrackingActive = !1),
			(this.isDebugMode = !1),
			(this.geoInfo = { latitude: void 0, longitude: void 0 }),
			(this.geoAutoTrack = !1),
			(this.geoCallback = void 0),
			(this.geoMode = 'off'),
			(this.appName = i),
			(this.appVersion = e),
			(this.countryCode = null),
			(this.networkInfo = { name: null, type: null }),
			this.initTracker(i, e);
	}
	initTracker(i, e) {
		i = {
			appId: i + '-' + e,
			contexts: { webPage: !0, session: !0, browser: !0 },
			withCredentials: !1,
			eventMethod: 'post',
			postPath: '/',
			customHeaders: { 'x-api-key': process.env.REACT_APP_API_KEY },
			bufferSize: 10,
			onRequestFailure: (i) => {
				this.isDebugMode && console.error('Event tracking SDK Log: Error to send events to the backend', i);
			},
			onRequestSuccess: (i) => {
				this.isDebugMode && console.info('Event tracking SDK Log: Events sent successfully to the backend', i);
			},
			resetActivityTrackingOnPageView: !1,
			plugins: [r()],
		};
		(k.instance = t('sp', process.env.REACT_APP_API_URL, i)),
			n(),
			s({ minimumVisitLength: 30, heartbeatDelay: 10 });
	}
	static getInstance(i, e) {
		return k.instance || (k.instance = new k(i, e)), k.instance;
	}
	_checkGeoParams(i, e, t) {
		switch (i) {
			case 'auto':
				return (
					(this.geoAutoTrack = !0),
					a(),
					e && t
						? 'geoInfo and geoCallback will be ignored'
						: e
							? 'geoInfo will be ignored'
							: t
								? 'geoCallback will be ignored'
								: ''
				);
			case 'manual':
				if (!e) return '"manual" geoMode is specified but no start geoInfo object is provided';
				if (((this.geoInfo = e), t)) return 'geoMode set to "manual", geoCallback will be ignored';
				break;
			case 'callback':
				return t
					? 'function' != typeof t
						? 'geoCallback is not a function, no geolocation information will be tracked'
						: ((this.geoCallback = t), '')
					: '"callback" geoMode is specified but no geoCallback is specified, no geolocation information will be tracked';
			case 'off':
				return 'Geolocation information tracking disabled';
			case void 0:
				return 'Geo mode not provided';
			default:
				return 'No supported geoMode is provided. Geolocation information tracking disabled';
		}
	}
	_setCountryCode(i) {
		return i
			? /^[0-9]{3}$/.test(i)
				? [
						'004',
						'008',
						'010',
						'012',
						'016',
						'020',
						'024',
						'028',
						'031',
						'032',
						'036',
						'040',
						'044',
						'048',
						'050',
						'051',
						'052',
						'056',
						'060',
						'064',
						'068',
						'070',
						'072',
						'074',
						'076',
						'084',
						'086',
						'090',
						'092',
						'096',
						'100',
						'104',
						'108',
						'112',
						'116',
						'120',
						'124',
						'132',
						'136',
						'140',
						'144',
						'148',
						'152',
						'156',
						'158',
						'162',
						'166',
						'170',
						'174',
						'175',
						'178',
						'180',
						'184',
						'188',
						'191',
						'192',
						'196',
						'203',
						'204',
						'208',
						'212',
						'214',
						'218',
						'222',
						'226',
						'231',
						'232',
						'233',
						'234',
						'238',
						'239',
						'242',
						'246',
						'248',
						'250',
						'254',
						'258',
						'260',
						'262',
						'266',
						'268',
						'270',
						'275',
						'276',
						'288',
						'292',
						'296',
						'300',
						'304',
						'308',
						'312',
						'316',
						'320',
						'324',
						'328',
						'332',
						'334',
						'336',
						'340',
						'344',
						'348',
						'352',
						'356',
						'360',
						'364',
						'368',
						'372',
						'376',
						'380',
						'384',
						'388',
						'392',
						'398',
						'400',
						'404',
						'408',
						'410',
						'414',
						'417',
						'418',
						'422',
						'426',
						'428',
						'430',
						'434',
						'438',
						'440',
						'442',
						'446',
						'450',
						'454',
						'458',
						'462',
						'466',
						'470',
						'474',
						'478',
						'480',
						'484',
						'490',
						'496',
						'498',
						'499',
						'500',
						'504',
						'508',
						'512',
						'516',
						'520',
						'524',
						'528',
						'531',
						'533',
						'534',
						'535',
						'540',
						'548',
						'554',
						'558',
						'562',
						'566',
						'570',
						'574',
						'578',
						'580',
						'583',
						'584',
						'585',
						'586',
						'591',
						'598',
						'600',
						'604',
						'608',
						'612',
						'616',
						'620',
						'624',
						'626',
						'630',
						'634',
						'638',
						'642',
						'643',
						'646',
						'652',
						'654',
						'659',
						'660',
						'662',
						'663',
						'666',
						'670',
						'674',
						'678',
						'682',
						'686',
						'688',
						'690',
						'694',
						'702',
						'703',
						'704',
						'705',
						'706',
						'710',
						'716',
						'724',
						'728',
						'729',
						'732',
						'740',
						'748',
						'752',
						'756',
						'760',
						'762',
						'764',
						'768',
						'772',
						'776',
						'780',
						'784',
						'788',
						'792',
						'795',
						'796',
						'798',
						'800',
						'804',
						'807',
						'818',
						'826',
						'831',
						'832',
						'834',
						'840',
						'850',
						'854',
						'858',
						'860',
						'862',
						'876',
						'882',
						'887',
						'894',
					].includes(i)
					? ((this.countryCode = i), '')
					: ((this.countryCode = null),
						'The country code does not correspond to any existing country. No country code will be collected')
				: ((this.countryCode = null),
					'The country code is not complaint to ISO 3166 numeric. No country code will be collected')
			: 'No country code provided. No country code will be collected';
	}
	_setNetworkInfo(e) {
		if (e) {
			let i = '';
			var t = ['MOBILE', 'WIFI', 'WIRED'];
			return (
				t.includes(e.type) ||
					(i +=
						'The network type must be include in [' +
						t.join(', ') +
						']. No network type will be collected'),
				(this.networkInfo.type = e.type),
				e.name
					? (this.networkInfo.name = e.name)
					: ((this.networkInfo.name = null),
						(i += (i ? '\n' : '') + 'Network name not specified. No network name will be collected')),
				i
			);
		}
		return (
			(this.networkInfo = { name: null, type: null }),
			'No network info provided. No network info will be collected'
		);
	}
	start_tracking(i, e, t, n, s, o) {
		if (this.isTrackingActive) console.warn('Tracking SDK already started');
		else {
			(this.isTrackingActive = !0), (this.isDebugMode = i), (this.geoMode = e);
			var r = { geo: '', countryCode: '', networkInfo: '' };
			if (
				((r.geo = this._checkGeoParams(e, t, n)),
				(r.countryCode = this._setCountryCode(s)),
				(r.networkInfo = this._setNetworkInfo(o)),
				this.isDebugMode)
			)
				for (const a in r) r[a] && console.warn(a + 'warning: ' + r[a]);
		}
	}
	stop_tracking() {
		this.isTrackingActive ? ((this.isTrackingActive = !1), i()) : console.warn('Tracking SDK is not yet started');
	}
	logEvent(i) {
		this.isDebugMode && console.log('Event tracking SDK Log: ', i);
	}
	updateGeoLocation(i) {
		'manual' === this.geoMode
			? this._updateTrackerGeoLocation(i)
			: 'manual' !== this.geoMode &&
				this.isDebugMode &&
				console.warn(
					'You are trying to update coordinate when the geoMode is not "manual" but is ' +
						this.geoMode +
						'No update will be performed'
				);
	}
	updateCountryCode(i) {
		i = this._setCountryCode(i);
		this.isDebugMode && i && console.warn(i);
	}
	updateNetworkInfo(i) {
		i = this._setNetworkInfo(i);
		this.isDebugMode && i && console.warn(i);
	}
	_addConstInfo(i) {
		var e,
			t,
			n = {
				...i,
				countryCode: this.countryCode,
				networkName: this.networkInfo.name,
				networkType: this.networkInfo.type,
				appName: this.appName,
				appVersion: this.appVersion,
			};
		return (
			'manual' === this.geoMode && this.geoInfo
				? ((n.latitude = this.geoInfo.latitude), (n.longitude = this.geoInfo.longitude))
				: 'callback' === this.geoMode &&
					this.geoCallback &&
					((e = this.geoCallback()),
					(t = this._checkGeoInfo(e)).error
						? t.error && this.isDebugMode && console.warn(t.msg)
						: ((n.latitude = e.latitude), (n.longitude = e.longitude))),
			null !== i.featureOrigin &&
				void 0 !== i.featureOrigin &&
				'string' != typeof i.featureOrigin &&
				console.warn('Event tracking SDK Log: featureOrigin is not a string'),
			n
		);
	}
	_checkGeoInfo(i) {
		var e;
		return i
			? i.hasOwnProperty('latitude') && i.hasOwnProperty('longitude')
				? (({ latitude: i, longitude: e } = i),
					'number' != typeof i || 'number' != typeof e
						? { error: !0, msg: 'Longitude or Latitude is not a number' }
						: i < -90 || 90 < i
							? { error: !0, msg: 'Latitude is not in [ -90, 90 ]' }
							: e < -180 || 180 < e
								? { error: !0, msg: 'Longitude is not in [ -180, 180 ]' }
								: { error: !1, msg: '' })
				: {
						error: !0,
						msg:
							('callback' === this.geoMode ? 'return value of geoCallback' : 'geoInfo object') +
							' not contains latitude or longitude properties',
					}
			: { error: !0, msg: 'No geoInfo specified' };
	}
	_updateTrackerGeoLocation(i) {
		var e = this._checkGeoInfo(i);
		e.error ? e.error && this.isDebugMode && console.warn(e.msg) : (this.geoInfo = i);
	}
	track_listening_event(i, e, t) {
		let n = {
			eventName: i.name,
			sessionId: i.listening_session_id,
			mediaId: i.media_id,
			mediaType: i.media_type,
			output: i.output,
			origin: i.origin,
			syncInfo: i.sync_info,
			featureOrigin: i.feature_origin || null,
		};
		'seek' === e
			? (n.seekType = t)
			: 'error' === e
				? (n.errorReason = t)
				: 'skip' === e &&
					((n.skipType = t.skip_type),
					(n.index = t.index),
					(n.previousMediaId = t.previous_media_id),
					(n.previousMediaType = t.previous_media_type)),
			(n = this._addConstInfo(n)),
			o({ event: { schema: k.schema_sn, data: n } }),
			this.logEvent(n);
	}
	track_mirroring_event(i) {
		(i = {
			eventName: i.name,
			sessionId: i.mirroring_session_id,
			mirroringType: i.type,
			origin: i.origin,
			featureOrigin: i.feature_origin || null,
		}),
			(i = this._addConstInfo(i));
		o({ event: { schema: k.schema_sn, data: i } }), this.logEvent(i);
	}
	track_error_event(i) {
		(i = {
			eventName: i.name,
			errorType: i.type,
			errorSource: i.source,
			errorReason: i.reason,
			origin: i.origin,
			extraInfo: i.extra_info ? JSON.stringify(i.extra_info) : null,
			featureOrigin: i.feature_origin || null,
		}),
			(i = this._addConstInfo(i));
		o({ event: { schema: k.schema_sn, data: i } }), this.logEvent(i);
	}
	track_setvalue_event(i) {
		(i = {
			eventName: i.name,
			key: i.key,
			value: i.value,
			origin: i.origin,
			featureOrigin: i.feature_origin || null,
		}),
			(i = this._addConstInfo(i));
		o({ event: { schema: k.schema_sn, data: i } }), this.logEvent(i);
	}
	track_action_event(i) {
		var e = {
				eventName: i.name,
				actionType: i.type,
				subject: JSON.stringify(i.subject),
				uiSource: JSON.stringify(i.ui_source),
				origin: i.origin,
				featureOrigin: i.feature_origin || null,
			},
			e = this._addConstInfo(e),
			i = i.checkActionStructure();
		if (this.isDebugMode) for (const t of i) console.warn(t);
		0 === i.length && (o({ event: { schema: k.schema_sn, data: e } }), this.logEvent(e));
	}
	isListeningSessionActive(i) {
		return !!this.sessionsMap.has(i) && this.sessionsMap.get(i).isActive;
	}
	isMirroringSessionActive() {
		return null != this.mirroringSession && this.mirroringSession.isMirroringSessionActive()
			? this.mirroringSession.isMirroringSessionActive()
			: (console.log('No mirroring session found'), !1);
	}
	fire_START_on(e, t, n, s, o, r, a) {
		if (this.isTrackingActive && !this.isListeningSessionActive(s)) {
			const c = new g(this);
			c.generateId()
				.then((i) => {
					(c.listening_session_id = i),
						c.start('listening_session_start', e, t, n, s, o, r, a),
						this.sessionsMap.set(s, c);
				})
				.catch((i) => {
					console.error('Error generating listening session ID: ', i);
				});
		}
	}
	fire_MIRRORING_START_on(e, t, n) {
		this.isTrackingActive &&
			((this.mirroringSession = new v(this)),
			this.mirroringSession
				.generateId()
				.then((i) => {
					(this.mirroringSession.mirroring_session_id = i),
						this.mirroringSession.start('mirroring_session_start', e, t, n);
				})
				.catch((i) => {
					console.error('Error generating mirroring_session ID: ', i);
				}));
	}
	fire_MIRRORING_STOP_on(i, e, t) {
		this.isTrackingActive &&
			(this.mirroringSession && this.mirroringSession.isMirroringSessionActive()
				? this.mirroringSession.stop('mirroring_session_stop', i, e, t)
				: console.warn(
						'Mirroring session tracking warning: You are trying to stop the session but no mirroring session is running.'
					));
	}
	fire_STOP_on(i, e, t, n, s, o) {
		this.isTrackingActive &&
			(this.sessionsMap.has(n)
				? (this.sessionsMap.get(n).stop('listening_session_stop', t, s, o), this.sessionsMap.delete(n))
				: console.warn(
						'Listen session tracking warning: You are trying to stop the session but no session is created for origin',
						n
					));
	}
	fire_ERROR_on(i, e, t, n, s, o, r) {
		this.isTrackingActive && ((e = new _(e, t, n, s, o, r)), this.track_error_event(e));
	}
	fire_CHANGE_on(i, e, t, n) {
		this.isTrackingActive && ((i = new h(i, e, t, n)), this.track_setvalue_event(i));
	}
	fire_ACTION_on(i, e, t, n, s) {
		this.isTrackingActive && ((i = new m(i, e, t, n, s)), this.track_action_event(i));
	}
	fire_SEEK_on(i, e, t, n, s, o, r) {
		this.isTrackingActive &&
			(this.isListeningSessionActive(n)
				? this.sessionsMap.get(n).fire_SEEK_on('listening_session_seek', i, e, t, n, s, o, r)
				: console.warn(
						'Listen session tracking warning: You are trying to register a seek event but no session is created for origin',
						n
					));
	}
	fire_PAUSE_on(i, e, t, n, s, o) {
		this.isTrackingActive &&
			(this.isListeningSessionActive(n)
				? this.sessionsMap.get(n).fire_PAUSE_on('listening_session_pause', i, e, t, n, s, o)
				: console.warn(
						'Listen session tracking warning: You are trying to pause the session but no session is created for origin',
						n
					));
	}
	fire_SKIP_on(i, e, t, n, s, o, r, a) {
		this.isTrackingActive &&
			(this.isListeningSessionActive(n)
				? this.sessionsMap.get(n).fire_SKIP_on('listening_session_skip', i, e, t, n, s, o, r, a)
				: console.warn(
						'Listen session tracking warning: You are trying to register a skip event but no session is created for origin',
						n
					));
	}
	fire_Listening_ERROR_on(i, e, t, n, s, o, r) {
		this.isTrackingActive &&
			(this.isListeningSessionActive(n)
				? this.sessionsMap.get(n).fire_ERROR_on('listening_session_error', i, e, t, n, s, o, r)
				: console.warn(
						'Listen session tracking warning: You are trying to register a seek event but no session is created for origin',
						n
					));
	}
}
let w;
function S(i) {
	var {
		media_id: i,
		media_type: e,
		position: t,
		origin: n,
		output: s,
		sync_info: o,
		seek_type: r,
		reason: a,
		index: c,
		skip_type: u,
		feature_origin: g,
	} = i;
	return {
		media_id: i,
		media_type: e,
		position: t,
		origin: n,
		output: s,
		sync_info: o,
		seek_type: r,
		reason: a,
		index: c,
		skip_type: u,
		feature_origin: g,
	};
}
function b(i) {
	var { media_id: i, media_type: e, position: t, origin: n, output: s, sync_info: o, feature_origin: r } = S(i);
	w && w.fire_START_on(i, e, t, n, s, o, r);
}
function A(i) {
	var { media_id: i, media_type: e, position: t, origin: n, output: s, seek_type: o, feature_origin: r } = S(i);
	w && w.fire_SEEK_on(i, e, t, n, s, o, r);
}
function T(i) {
	var { media_id: i, media_type: e, position: t, origin: n, output: s, feature_origin: o } = S(i);
	w && w.fire_PAUSE_on(i, e, t, n, s, o);
}
function I(i) {
	var {
		media_id: i,
		media_type: e,
		position: t,
		origin: n,
		output: s,
		index: o,
		skip_type: r,
		feature_origin: a,
	} = S(i);
	w && w.fire_SKIP_on(i, e, t, n, s, o, r, a);
}
function R(i) {
	var { media_id: i, media_type: e, position: t, origin: n, output: s, reason: o, feature_origin: r } = S(i);
	w && w.fire_Listening_ERROR_on(i, e, t, n, s, o, r);
}
function P(i) {
	var { media_id: i, media_type: e, position: t, origin: n, output: s, feature_origin: o } = S(i);
	w && w.fire_STOP_on(i, e, t, n, s, o);
}
function M(i) {
	var { error: i, type: e, source: t, reason: n, origin: s, extra_info: o, feature_origin: r } = i;
	w && w.fire_ERROR_on(i, e, t, n, s, o, r);
}
function N(i) {
	var { type: i, subject: e, ui_source: t, origin: n, feature_origin: s } = i;
	w && w.fire_ACTION_on(i, e, t, n, s);
}
function E(i) {
	var { key: i, value: e, origin: t, feature_origin: n } = i;
	w && w.fire_CHANGE_on(i, e, t, n);
}
function O(i) {
	var { type: i, origin: e, feature_origin: t } = i;
	w && w.fire_MIRRORING_START_on(i, e, t);
}
function C(i) {
	var { type: i, origin: e, feature_origin: t } = i;
	w && w.fire_MIRRORING_STOP_on(i, e, t);
}
function L(i) {
	var { media_id: i, media_type: e, position: t, origin: n, output: s, feature_origin: o } = i;
	w && (w.isListeningSessionActive(n) ? w.fire_STOP_on(i, e, t, n, s, o) : w.fire_START_on(i, e, t, n, s, o));
}
function $(i) {
	var { type: i, origin: e, feature_origin: t } = i;
	w && (w.isMirroringSessionActive() ? w.fire_MIRRORING_STOP_on(i, e, t) : w.fire_MIRRORING_START_on(i, e, t));
}
function D(i) {
	var {
		debug: i,
		geoMode: e,
		geoInfo: t,
		geoCallback: n,
		appName: s,
		appVersion: o,
		countryCode: r,
		networkInfo: a,
	} = i;
	(w = w || k.getInstance(s, o)).start_tracking(i, e, t, n, r, a);
}
function G(i) {
	w.updateGeoLocation(i);
}
function W(i) {
	w.updateCountryCode(i);
}
function j(i) {
	w.updateNetworkInfo(i);
}
function x() {
	w.stop_tracking(), (w = null);
}
export {
	N as action_fire_ACTION_on,
	M as error_fire_ERROR_on,
	R as listening_fire_ERROR_on,
	T as listening_fire_PAUSE_on,
	A as listening_fire_SEEK_on,
	I as listening_fire_SKIP_on,
	L as listening_fire_START_STOP_on,
	b as listening_fire_START_on,
	P as listening_fire_STOP_on,
	$ as mirroring_fire_START_STOP_on,
	O as mirroring_fire_START_on,
	C as mirroring_fire_STOP_on,
	E as setvalue_fire_CHANGE_on,
	D as start_tracking,
	x as stop_tracking,
	W as updateCountryCode,
	G as updateGeoLocation,
	j as updateNetworkInfo,
};
