import React from 'react';
import './style.css';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/**
 * Bouton affichant une catégorie de radio
 *
 * OnClic : Redirection vers l'url de la catégorie
 *
 * @param {object} item category item
 * @param {boolean} selected isSelected
 * @returns JSX
 */
function CategoryItem({ item, selected }) {
	const navigate = useNavigate();
	const catName = item.name?.replaceAll(' ', '_');
	const onClick = () => {
		navigate(`/categorie/${catName}`);
	};
	return (
		<Button
			onClick={onClick}
			variant={selected ? 'info' : 'primary'}
			className="catItemCon"
			key={item.id.toString()}
		>
			{item.name}
		</Button>
	);
}

export default CategoryItem;
