import { SET_CURRENT_FEED, SET_FEEDS } from '../actions/constants';

const defaultState = {
	allFeeds: [],
	currentFeed: {},
	showMore: false,
};

export default function feed(state = defaultState, action) {
	switch (action.type) {
		case SET_FEEDS:
			return {
				...state,
				allFeeds:
					action?.payload?.page === 0 ? action?.payload?.list : [...state.allFeeds, ...action?.payload?.list],
				isLastPage: action?.payload?.isLastPage,
				currentPageFirstFeedId: action?.payload?.list[0]?.id,
			};
		case SET_CURRENT_FEED:
			return { ...state, currentFeed: action?.payload };

		default:
			return state;
	}
}
