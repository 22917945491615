import {
	PUSH_RADIOS,
	SET_CURRENT_RADIO,
	SET_FEATURED_RADIOS,
	SET_SAME_PARENT_RADIOS,
	SET_CURRENT_RADIOS_STREAMS,
	SET_FEEDS_PODCASTS,
	SET_EPISODES_PODCASTS,
} from './constants';
import apiInstance from '../../helpers/apiInstance';

export const getFeaturedRadios = () => {
	return (dispatch) =>
		apiInstance
			.get('/medias/website/recommendations')
			.then(({ data }) => {
				if (data.services) {
					dispatch({
						type: SET_FEATURED_RADIOS,
						payload: data.services,
					});
				}
			})
			.catch((error) => {});
};

export const getRadios = (params) => {
	return (dispatch) =>
		apiInstance
			.get(`/radios`, { params })
			.then(({ data }) => {
				if (data.services) {
					dispatch({
						type: PUSH_RADIOS,
						payload: {
							list: data.services,
						},
					});
				}
			})
			.catch((error) => {});
};

export const getRadioById = (id, navigate) => {
	return (dispatch) =>
		apiInstance
			.get(`/radios/${id}`)
			.then(({ data }) => {
				if (data.service) {
					dispatch({
						type: SET_CURRENT_RADIO,
						payload: data.service,
					});
				} else {
					navigate('/');
				}
			})
			.catch((error) => {
				navigate('/');
			});
};

export const getSameParentRadios = (id) => {
	return (dispatch) =>
		apiInstance
			.get(`/radios/${id}/children`)
			.then(({ data }) => {
				if (data.services) {
					dispatch({
						type: SET_SAME_PARENT_RADIOS,
						payload: data.services,
					});
				}
			})
			.catch((error) => {});
};

export const getStreamsRadioById = async (id, navigate) => {
	const response = await apiInstance.get(`/radios/${id}/streams`);
	try {
		if (response.data.streams) {
			return (dispatch) =>
				dispatch({
					type: SET_CURRENT_RADIOS_STREAMS,
					payload: response.data.streams,
				});
		}
	} catch (error) {
		navigate('/');
	}
};

// get actives radio podcasts feeds by rpid
export const getRadioPodcastsFeeds = (rpid) => {
	return (dispatch) =>
		apiInstance
			.get(`/radios/${rpid}/podcasts-feeds`)
			.then(({ data }) => {
				if (data.podcastsFeeds.length > 0) {
					dispatch({
						type: SET_FEEDS_PODCASTS,
						payload: data.podcastsFeeds.length > 0 ? data.podcastsFeeds : [],
					});
				}
			})
			.catch((error) => {});
};

// get last 10 radio podcasts episodes by rpid
export const getRadioLastBroadcastedPodcastsEpisodes = (rpid) => {
	return (dispatch) =>
		apiInstance
			.get(`/radios/${rpid}/last-broadcasted-podcasts-episodes`)
			.then(({ data }) => {
				if (data.podcastsEpisodes.length > 0) {
					dispatch({
						type: SET_EPISODES_PODCASTS,
						payload: data.podcastsEpisodes.length > 0 ? data.podcastsEpisodes : [],
					});
				}
			})
			.catch((error) => {});
};
