import React from 'react';
import './style.css';

const Loading = ({ text, style }) => {
	return (
		<div className="emptyContainer" style={style}>
			<h4>{text}</h4>
		</div>
	);
};

export default Loading;
