import { SET_LOADING_SEARCH, SET_SUGGEST_RADIOS, SET_SUGGEST_PODCASTS } from '../actions/constants';

const defaultState = {
	suggestions: [],
	suggestionsPodcasts: [],
};

export default function radio(state = defaultState, action) {
	switch (action.type) {
		case SET_SUGGEST_RADIOS:
			return { ...state, suggestions: action?.payload };
		case SET_SUGGEST_PODCASTS:
			return { ...state, suggestionsPodcasts: action?.payload };
		case SET_LOADING_SEARCH:
			return { ...state, loading: action?.payload };

		default:
			return state;
	}
}
