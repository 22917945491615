import React, { useEffect, useState } from 'react';
import './style.css';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import translate from '../../translation';
import { scrollTop } from '../../helpers/behavior';
import { Loading } from '../../components';
import { Helmet } from 'react-helmet';
import apiInstance from '../../helpers/apiInstance';

const Contact = (props) => {
	const errors = {
		name: translate('Le champ nom est obligatoire'),
		email: translate("Le champ email n'est pas valide"),
		subject: translate('Le champ sujet est obligatoire'),
		message: translate('Le champ message est obligatoire'),
	};
	const [nameInvalid, setNameInvalid] = useState(false);
	const [emailInvalid, setEmailInvalid] = useState(false);
	const [subjectInvalid, setSubjectInvalid] = useState(false);
	const [messageInvalid, setMessageInvalid] = useState(false);
	const [isMailSended, setMailSended] = useState();
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		if (isMailSended) document.contactForm.reset();
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		scrollTop();
	}, []);

	const onFormSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target),
			formDataObj = Object.fromEntries(formData.entries());
		const emailRegex =
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
		setNameInvalid(formDataObj.name?.length > 0 ? false : true);
		setEmailInvalid(emailRegex.test(formDataObj.email) ? false : true);
		setSubjectInvalid(formDataObj.subject?.length > 0 ? false : true);
		setMessageInvalid(formDataObj.message?.length > 0 ? false : true);
		if (
			formDataObj.name?.length > 0 &&
			emailRegex.test(formDataObj.email) &&
			formDataObj.subject?.length > 0 &&
			formDataObj.message?.length > 0
		) {
			doRequest(formDataObj);
		}
	};

	const doRequest = (params) => {
		setLoading(true);
		handleShow();
		apiInstance
			.post(`/contact`, params)
			.then(async ({ data }) => {
				setMailSended(true);
			})
			.catch((error) => {
				setMailSended(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div id="contactPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section1">
				<Container>
					<h1>{translate('Contact')} </h1>
				</Container>
			</section>
			<section className="section2">
				<Container>
					<Row>
						<Col md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 2 }}>
							<h5 className="section2Text">{translate('contactText')}</h5>
							<Form name="contactForm" className="my-4" onSubmit={onFormSubmit}>
								<Form.Group className="mb-md-4" controlId="name">
									<Form.Label>{translate('Nom')}</Form.Label>
									<Form.Control
										isInvalid={nameInvalid}
										name="name"
										type="name"
										placeholder={translate('Saisir votre nom')}
									/>
									<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-md-4" controlId="email">
									<Form.Label>{translate('Email')}</Form.Label>
									<Form.Control
										isInvalid={emailInvalid}
										name="email"
										type="email"
										placeholder={translate('Saisir votre adresse e-mail')}
									/>
									<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-md-4" controlId="subject">
									<Form.Label>{translate('Sujet')}</Form.Label>
									<Form.Control
										isInvalid={subjectInvalid}
										name="subject"
										type="text"
										placeholder={translate('Saisir votre sujet')}
									/>
									<Form.Control.Feedback type="invalid">{errors.subject}</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-md-5" controlId="message">
									<Form.Label>{translate('Message')}</Form.Label>
									<Form.Control
										isInvalid={messageInvalid}
										name="message"
										as="textarea"
										rows={5}
										placeholder={translate('messagePlaceholder')}
									/>
									<Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
								</Form.Group>
								<div className="d-flex flex-row align-items-center">
									<Button variant="primary" type="submit">
										{translate('Envoyer le message')}
									</Button>
									{loading && <Loading size={35} color="#cf2345" conClass="ms-3" />}
								</div>
							</Form>
						</Col>
					</Row>
				</Container>
			</section>
			<Modal
				{...props}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={handleClose}
			>
				<Modal.Header closeButton style={{ border: 'none' }}></Modal.Header>
				<Modal.Body className={isMailSended ? 'text-success fs-5' : 'text-danger fs-5'}>
					{isMailSended ? 'Votre message a bien été envoyé !' : "Votre message n'a pas pu être envoyé."}
				</Modal.Body>
				<Modal.Footer closeButton style={{ border: 'none' }}>
					<Button className="text-secondary" onClick={handleClose}>
						Fermer
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Contact;
