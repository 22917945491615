import React from 'react';

const Volume = ({ size, color, playing }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={!playing ? size + 4 : size}
			height={!playing ? size + 4 : size}
			viewBox={`0 0 25 25`}
		>
			<g data-name="Layer 2">
				{!playing ? (
					<g data-name="arrow-right">
						<rect fill={color} transform="rotate(180 12 12)" opacity="0" />
						<path
							fill={color}
							d="M10.46 18a2.23 2.23 0 0 1-.91-.2 1.76 1.76 0 0 1-1.05-1.59V7.79A1.76 1.76 0 0 1 9.55 6.2a2.1 2.1 0 0 1 2.21.26l5.1 4.21a1.7 1.7 0 0 1 0 2.66l-5.1 4.21a2.06 2.06 0 0 1-1.3.46zm0-10v7.9l4.86-3.9z"
						/>
					</g>
				) : (
					<g data-name="arrow-right">
						<path
							fill={color}
							d="M16.5,5.9c-0.8,0-1.5,0.7-1.5,1.5v9c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-9C18,6.6,17.3,5.9,16.5,5.9z"
						/>
						<path
							fill={color}
							d="M8.5,5.9c-0.8,0-1.5,0.7-1.5,1.5v9c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-9C10,6.6,9.3,5.9,8.5,5.9z"
						/>
					</g>
				)}
			</g>
		</svg>
	);
};
export default Volume;
