import moment from 'moment';
import React from 'react';

const EmissionItem = ({ item, currentRadio }) => {
	const start = moment(item.heure_debut).format('HH:mm');
	const end = moment(item.heure_fin).format('HH:mm');

	return (
		<div className="emissionItem">
			<div className="emissionItemCard">{start}</div>
			<div className="emissionItemCard bg-white" style={{ backgroundImage: `url(${item?.image_1400})` }} />
			<div className="emissionItemContent">
				<h5>
					<span className="clock"></span>
					{start}
					<span className="arrow-right"></span>
					{end}
				</h5>
				<h2>{item.nom}</h2>
				<p>{item.description}</p>
			</div>
		</div>
	);
};

export default EmissionItem;
