import React, { useRef } from 'react';
import { CategoryItem } from '../../../components';
import Slider from 'react-slick';

/**
 * Slider contenant les boutons de catégories de radios
 * @param {array} categories
 * @returns JSX
 */
function Categories({ categories }) {
	const settings = {
		infinite: true,
		speed: 300,
		slidesToShow: 5,
		slidesPerRow: 1,
		slidesToScroll: 1,
		lazyLoad: true,
		cssEase: 'ease-out',
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
					rows: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					draggable: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					draggable: true,
				},
			},
		],
	};
	const sliderRef = useRef();

	sliderRef?.current?.slickGoTo(0);

	return (
		<Slider ref={sliderRef} {...settings}>
			{categories.map((item) => (
				<CategoryItem key={item?.id?.toString()} item={item} />
			))}
		</Slider>
	);
}

export default Categories;
