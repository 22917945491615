import React from 'react';
import './style.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const Loading = ({ color, size, conClass, style }) => {
	return (
		<div className={conClass} style={style}>
			<AiOutlineLoading3Quarters color={color} size={size} className="loader" />
		</div>
	);
};

export default Loading;
