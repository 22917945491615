import {
	PUSH_PODCAST,
	SET_TOP_PODCAST,
	SET_CATEGORIES_PODCAST,
	SET_SERIES_PODCAST,
	SET_GENRE_PODCAST,
	SET_CURRENT_PODCAST,
} from './constants';
import apiInstance from '../../helpers/apiInstance';

export const getPodcastsAlaune = () => {
	return (dispatch) =>
		apiInstance
			.get(`/medias/website/recommendations/podcasts`)
			.then(({ data }) => {
				if (data.items) {
					dispatch({ type: PUSH_PODCAST, payload: data.items });
				}
			})
			.catch((error) => {});
};

export const getPodcastsTop = () => {
	return (dispatch) =>
		apiInstance
			.get(`/categories/podcasts/top`)
			.then(({ data }) => {
				if (data.categories) {
					dispatch({
						type: SET_TOP_PODCAST,
						payload: data.categories,
					});
				}
			})
			.catch((error) => {});
};

export const getPodcastsCategories = () => {
	return (dispatch) =>
		apiInstance
			.get(`/categories/podcasts`)
			.then(({ data }) => {
				if (data.categories) {
					dispatch({
						type: SET_CATEGORIES_PODCAST,
						payload: data.categories,
					});
				}
			})
			.catch((error) => {});
};

export const getPodcastsSeries = (params, navigate) => {
	return (dispatch) =>
		apiInstance
			.get(`/podcasts/${params}/episodes`)
			.then(({ data }) => {
				if (data.episodes.length > 0) {
					dispatch({
						type: SET_SERIES_PODCAST,
						payload: data.episodes,
					});
				}
			})
			.catch((error) => {});
};

export const getPodcastsGenre = (cat, navigate) => {
	return (dispatch) =>
		apiInstance
			.get(`/podcasts?cat=${cat}`)
			.then(({ data }) => {
				if (data.podcasts) {
					dispatch({
						type: SET_GENRE_PODCAST,
						payload: data.podcasts,
					});
				}
			})
			.catch((error) => {});
};

export const getCurrentPodcast = (id, navigate) => {
	return (dispatch) =>
		apiInstance
			.get(`/podcasts/${id}`)
			.then(({ data }) => {
				if (data.podcast) {
					dispatch({
						type: SET_CURRENT_PODCAST,
						payload: data.podcast,
					});
				}
			})
			.catch((error) => {});
};
