import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import translate from '../../translation';
import { Empty, Loading, RadioItem, ShareModal, PodcastsTopSlider } from '../../components';
import { scrollTop } from '../../helpers/behavior';
import { IoPlay } from 'react-icons/io5';
import { TiMediaPause } from 'react-icons/ti';
import {
	SET_PLAYER,
	SET_RADIO_PROGRAM_LOADING,
	SET_SERIES_PODCAST,
	SET_EPISODES_PODCASTS,
	SET_FEEDS_PODCASTS,
} from '../../store/actions/constants';
import { useNavigate, useParams } from 'react-router-dom';
import {
	getRadioById,
	getSameParentRadios,
	getRadioPodcastsFeeds,
	getRadioLastBroadcastedPodcastsEpisodes,
} from '../../store/actions/radio';
import { getRadioPrograms } from '../../store/actions/program';
import { site_url } from '../../config/keys';
import { getNextSevenDays } from '../../helpers/radio';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import EmissionItem from './childs/EmissionItem';
import { getStreamsRadioById } from '../../store/actions/radio';
import Trackable from '../../rp_sdk/tracking_components/Trackable/trackable';
import Listening from '../../rp_sdk/tracking_components/Listening/listening';
import Action from '../../rp_sdk/tracking_components/Action/action';

const Single = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { rpID } = useParams();
	const currentRpid = rpID?.split('-')[0];
	const { playing, stream: playedRadio } = useSelector((state) => state.player);
	const { currentRadio, sameParentRadios, radioPodcastsFeeds, radioPodcastsEpisodes } = useSelector(
		(state) => state.radio
	);
	const { emissions, loading } = useSelector((state) => state.program);
	const [selectedDay, setSelectedDay] = useState({
		label: moment().format('dddd'),
		value: moment().format('YYYY-MM-DD'),
	});
	const [showShare, setShowShare] = useState(false);
	const days = getNextSevenDays();
	const { innerWidth: width } = window;
	const radio_url = `${site_url}/radio/${currentRadio.rpID}-${currentRadio.nom?.trim()?.replace(/ /g, '-')}`;
	const groupId = localStorage.getItem('uuid');

	useEffect(() => {
		scrollTop();
	}, [currentRadio]);
	useEffect(() => {
		dispatch({ type: SET_SERIES_PODCAST, payload: [] });
		dispatch({ type: SET_EPISODES_PODCASTS, payload: null });
		dispatch({ type: SET_FEEDS_PODCASTS, payload: null });
	}, [dispatch]);
	useEffect(() => {
		dispatch(getRadioById(currentRpid, navigate));
	}, [dispatch, currentRpid, navigate]);
	useEffect(() => {
		dispatch(getSameParentRadios(currentRpid));
	}, [dispatch, currentRpid]);
	useEffect(() => {
		dispatch({ type: SET_RADIO_PROGRAM_LOADING, payload: true });
		dispatch(getRadioPrograms(currentRpid, { date: selectedDay.value }));
	}, [dispatch, currentRpid, selectedDay]);
	useEffect(() => {
		dispatch(getRadioPodcastsFeeds(currentRpid));
	}, [dispatch, currentRpid]);
	useEffect(() => {
		dispatch(getRadioLastBroadcastedPodcastsEpisodes(currentRpid));
	}, [dispatch, currentRpid]);

	const handleOnPlay = async () => {
		const startPlaying = !(playing && playedRadio.rpID === currentRadio.rpID);
		const isNewRadioPlaying = playing && !(playedRadio.rpID === currentRadio.rpID);
		if (isNewRadioPlaying) {
			const playButton = document.getElementById('listening_stop');
			if (playButton) playButton.click();
		}
		if (startPlaying) {
			dispatch(await getStreamsRadioById(currentRadio.rpID, navigate));
		}
		const payload = {
			playing: startPlaying,
			stream: {
				nom: currentRadio.nom,
				description: currentRadio.description,
				rpID: currentRadio.rpID,
				image: currentRadio.image?.url,
				stream: currentRadio.stream,
				url: '',
			},
			playedSeconds: 0,
			isRadio: true,
			userClick: true,
		};
		if (!startPlaying) payload.lastRpidPlayed = currentRadio.rpID;
		dispatch({ type: SET_PLAYER, payload: payload });
	};
	const onShare = () => {
		if (width >= 768) {
			setShowShare(true);
		} else {
			if (navigator.share) {
				navigator
					.share({
						url: `${site_url}/radio/${currentRadio.rpID}-${currentRadio.nom?.trim()?.replace(/ /g, '-')}`,
					})
					.then(() => {})
					.catch(console.error);
			} else {
				setShowShare(true);
			}
		}
	};

	const onClickDay = (day) => {
		setSelectedDay(day);
	};

	return (
		<div id="singlePage">
			<Helmet>
				<title>{`Ecouter ${currentRadio.nom} sur Radioplayer - Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={currentRadio.description} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
				<meta property="og:title" content={currentRadio.nom} />
				<meta property="og:description" content={currentRadio.description} />
				<meta property="og:image" content={currentRadio?.image?.url} />
				<meta property="og:url" content={radio_url} />
			</Helmet>
			<section className="section1">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="text-center">
							<div
								className="radioImg"
								style={{
									backgroundImage: `url(${currentRadio?.image?.url})`,
								}}
							></div>
							<h2>{currentRadio.nom}</h2>
							<p>{currentRadio.description}</p>
							{(currentRadio.facebook || currentRadio.twitter) && (
								<div className="social">
									{currentRadio.facebook && (
										<Button
											className="facebook"
											href={'https://facebook.com/' + currentRadio.facebook}
											target="_blank"
										/>
									)}
									{currentRadio.twitter && (
										<Button
											className="twitter"
											href={'https://twitter.com/' + currentRadio.twitter}
											target="_blank"
										/>
									)}
								</div>
							)}

							<div className="col buttonsWrapper">
								{currentRadio.urlSite && (
									<Button variant="site" href={currentRadio.site} target="_blank">
										{translate('Site officiel')}
									</Button>
								)}
								<Trackable name="ShareTracking">
									<Action
										fire_ACTION_on={{ tag: 'Button', event: 'onClick', id: 'share_button' }}
										subject={{ mediaId: String(currentRadio?.rpID), mediaType: 'STATION' }}
										type="shareRequest"
									/>
									<Button variant="share" onClick={onShare} id="share_button">
										{translate('Partager')}
									</Button>
								</Trackable>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="text-center">
							<Trackable name="ListeningTracking">
								<Listening
									fire_START_on={{
										tag: 'Button',
										event: 'onClick',
										id: 'listening_single_start',
										sync_info: {
											groupId: groupId,
											previouslyDevicePlatform: 'RPWGCR',
											currentlyPlayed: playing,
										},
									}}
									fire_STOP_on={{ tag: 'Button', event: 'onClick', id: 'listening_single_stop' }}
									media_id={currentRadio?.rpID}
									media_type={'STATION'}
									position="0"
									output="speakers"
								/>

								{playing && playedRadio.rpID === currentRadio.rpID ? (
									<Button onClick={handleOnPlay} variant="play" id="listening_single_stop">
										<TiMediaPause color="#000" size={55} />
									</Button>
								) : (
									<Button onClick={handleOnPlay} variant="play" id="listening_single_start">
										<IoPlay color="#000" size={45} />
									</Button>
								)}
							</Trackable>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section2">
				{emissions && emissions.length > 0 && (
					<Container>
						<h2 className="title mt-5">{translate('Les émissions de') + ' ' + currentRadio.nom}</h2>
						<Row>
							<Col md={{ span: 10, offset: 1 }} className="text-center days-con">
								{days.map((day) => (
									<Button
										variant="tab"
										onClick={() => onClickDay(day)}
										className={selectedDay.label === day.label && 'active'}
										key={day?.label?.toString()}
									>
										{translate(day.label)}
									</Button>
								))}
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 10, offset: 1 }}>
								<div className="emissionsCon">
									{loading ? (
										<Loading color="black" size={30} conClass="loading" />
									) : emissions.length === 0 ? (
										<Empty text={translate('Aucune emission ce jour')} />
									) : (
										emissions.map((emission, index) => (
											<EmissionItem
												item={emission}
												currentRadio={currentRadio}
												key={index?.toString()}
											/>
										))
									)}
								</div>
							</Col>
						</Row>
					</Container>
				)}
			</section>
			{radioPodcastsEpisodes && (
				<section className="section4 container">
					<div className="carousel-container">
						<h2 className="title mt-5">{translate('Les dernières émissions') + ' ' + currentRadio.nom}</h2>
						<div className="carousel">
							<PodcastsTopSlider podcasts={radioPodcastsEpisodes} isEpisode={true} />
						</div>
					</div>
				</section>
			)}
			{radioPodcastsFeeds && (
				<section className="section4 container">
					<div>
						<h2 className="title mt-5">{translate('Les podcasts de') + ' ' + currentRadio.nom}</h2>
						<div className="carousel">
							<PodcastsTopSlider podcasts={radioPodcastsFeeds} />
						</div>
					</div>
				</section>
			)}
			{sameParentRadios && sameParentRadios.length > 0 && (
				<section className="section3">
					<Container>
						<h2 className="title">{translate('Les autres radios de') + ' ' + currentRadio.nom}</h2>
						<div className="radiosBlock">
							{sameParentRadios.map((item) => (
								<RadioItem key={item?.rpID?.toString()} item={item} />
							))}
						</div>
					</Container>
				</section>
			)}
			<ShareModal
				//title={translate("ShareTitle")}
				shareurl={radio_url}
				show={showShare}
				onHide={() => setShowShare(false)}
			/>
		</div>
	);
};

export default Single;
