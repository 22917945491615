import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';
import { Helmet } from 'react-helmet';
import { scrollTop } from '../../helpers/behavior';

const Mentions = () => {
	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div id="footerPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta
					name="description"
					content="Retrouvez les radios françaises et les podcasts sur Radioplayer France. Plus de 800 radios et des milliers de podcasts à votre disposition."
				/>
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<div>
				<section className="section1">
					<Container>
						<h1>Mentions légales</h1>
					</Container>
				</section>
				<section className="section2">
					<Container>
						<h3>Editeur du site internet</h3>
						<p>
							Le présent site (ci-après, le «&nbsp;Site&nbsp;») est la propriété de la société COSMOS,
							Société par Actions Simplifiée au capital de 90,000 Euros, immatriculée au Registre du
							Commerce et des Sociétés de Paris sous le numéro 888 335 759 (TVA intra-communautaire&nbsp;:
							FR FR92888335759) et dont le siège social est situé 14 rue Lübeck 75016 à Paris (ci-après,
							«&nbsp;COSMOS&nbsp;»).
						</p>
						<p>Son mail est :&nbsp;applications@cosmos.radio</p>
						<p>Le présent Site comporte un certain nombre d’informations commerciales.</p>
						<h3>Directeur de la publication</h3>
						<p>
							Le directeur de la publication du Site de la société COSMOS est Monsieur Jean-Eric VALLI, en
							qualité de représentant légal de la société COSMOS.
						</p>
						<h3>Hébergeur du site internet</h3>
						<p>
							Le Site est hébergé par Amazon Web Services LLC, dont le siège social est situé P.O Box
							81226 Seattle, WA 98108-1226
						</p>
					</Container>
				</section>
			</div>
		</div>
	);
};

export default Mentions;
