import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { scrollToOffset, scrollTop } from '../../helpers/behavior';
import translate from '../../translation';
import { Tab1, Tab2, Tab3, Tab4, Tab5, Tab6, Tab7 } from '../../assets/icons';
import { appStore, googlePlay, ipad, phone, tv, watch, car, enceinte, amazon } from '../../assets/images';

const Cms = () => {
	const [tabOffset, setTabOffset] = useState(null);
	const [active, setActive] = useState(1);
	const [fixed, setFixed] = useState(false);
	const { innerWidth: width } = window;

	useEffect(() => {
		const tabbar = document.getElementById('aboutabbar');
		setTabOffset(tabbar?.offsetTop);
	}, []);

	useEffect(() => {
		const handleScroll = (event) => {
			if (window.pageYOffset >= tabOffset) {
				setFixed(true);
			} else {
				setFixed(false);
			}
			let index = 1;
			Array(7)
				.fill()
				.forEach((element, i) => {
					const elem = document.getElementById(`aboutab${i + 1}`);
					if (window.pageYOffset >= elem.offsetTop - 400) {
						index = i + 1;
					}
				});
			setActive(index);
		};
		if (tabOffset) {
			window.addEventListener('scroll', handleScroll);
			return () => window.removeEventListener('scroll', handleScroll);
		}
	}, [tabOffset]);

	useEffect(() => {
		scrollTop();
	}, []);

	const onSelectTab = (index) => {
		const elem = document.getElementById(`aboutab${index}`);
		scrollToOffset(elem.offsetTop - 300);
		setActive(index);
	};

	return (
		<div id="aboutPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section1">
				<Container>
					<h1>
						{translate(
							'Retrouvez les radios et podcasts de Radioplayer sur tous vos environnements connectés'
						)}{' '}
					</h1>
				</Container>
			</section>
			<section className={`section2 ${fixed && 'headerfixed'}`} id="aboutabbar">
				<Container>
					<div className="tabs">
						<div onClick={() => onSelectTab(1)} className={`tab ${active === 1 ? 'active' : ''}`}>
							<img alt="Applications mobiles" style={{ opacity: active === 1 ? 1 : 0.2 }} src={Tab1} />
							<h5>{translate('Applications mobiles')}</h5>
						</div>
						<div onClick={() => onSelectTab(2)} className={`tab ${active === 2 ? 'active' : ''}`}>
							<img alt="Tablettes" style={{ opacity: active === 2 ? 1 : 0.2 }} src={Tab2} />
							<h5>{translate('Tablettes')}</h5>
						</div>
						<div onClick={() => onSelectTab(3)} className={`tab ${active === 3 ? 'active' : ''}`}>
							<img alt="Objet connectés" style={{ opacity: active === 3 ? 1 : 0.2 }} src={Tab3} />
							<h5>{translate('Objet connectés')}</h5>
						</div>
						<div onClick={() => onSelectTab(4)} className={`tab ${active === 4 ? 'active' : ''}`}>
							<img alt="Enceintes connectées" style={{ opacity: active === 4 ? 1 : 0.2 }} src={Tab4} />
							<h5>{translate('Enceintes connectées')}</h5>
						</div>
						<div onClick={() => onSelectTab(5)} className={`tab ${active === 5 ? 'active' : ''}`}>
							<img alt="Assistants vocaux" style={{ opacity: active === 5 ? 1 : 0.2 }} src={Tab5} />
							<h5>{translate('Assistants vocaux')}</h5>
						</div>
						<div onClick={() => onSelectTab(6)} className={`tab ${active === 6 ? 'active' : ''}`}>
							<img alt="Smart TV" style={{ opacity: active === 6 ? 1 : 0.2 }} src={Tab6} />
							<h5>{translate('Smart TV')}</h5>
						</div>
						<div onClick={() => onSelectTab(7)} className={`tab ${active === 7 ? 'active' : ''}`}>
							<img alt="Voitures" style={{ opacity: active === 7 ? 1 : 0.2 }} src={Tab7} />
							<h5>{translate('Voitures')}</h5>
						</div>
					</div>
				</Container>
			</section>
			<section className="section3">
				<Container>
					<Row className="tab-content" id="aboutab1">
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'last' },
							})}
						>
							<h3>{translate('Applications mobiles')}</h3>
							<h5>{translate('iOS, Android, Huawei')}</h5>
							<p>{translate('mobile_description')}</p>
							<div className="mt-2">
								<Button
									target="_blank"
									href="https://apps.apple.com/fr/app/radioplayer/id6443602613"
									className="appLink me-2"
									variant="link"
								>
									<img src={appStore} alt="" />
								</Button>
								<Button
									target="_blank"
									href="https://play.google.com/store/apps/details?id=com.radioplayer.mobile&pcampaignid=web_share"
									className="appLink"
									variant="link"
								>
									<img src={googlePlay} alt="" />
								</Button>
							</div>
						</Col>
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'first' },
							})}
						>
							<div className="tab-image" style={{ backgroundImage: `url(${phone})` }} />
						</Col>
					</Row>
					<Row className="tab-content" id="aboutab2">
						<Col md={6}>
							<div className="tab-image" style={{ backgroundImage: `url(${ipad})` }} />
						</Col>
						<Col md={6}>
							<h3>{translate('Tablettes')}</h3>
							<h5>{translate('iPad, Android devices')}</h5>
							<p>{translate('tablet_description')}</p>
							<div className="mt-2">
								<Button
									target="_blank"
									href="https://apps.apple.com/fr/app/radioplayer/id6443602613"
									className="appLink me-2"
									variant="link"
								>
									<img src={appStore} alt="" />
								</Button>
								<Button
									target="_blank"
									href="https://play.google.com/store/apps/details?id=com.radioplayer.mobile&pcampaignid=web_share"
									className="appLink"
									variant="link"
								>
									<img src={googlePlay} alt="" />
								</Button>
							</div>
						</Col>
					</Row>
					<Row className="tab-content" id="aboutab3">
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'last' },
							})}
						>
							<h3>{translate('Objet connectés')}</h3>
							<h5>{translate('Apple Watch, Android Wear')}</h5>
							<p>{translate('object_description')}</p>
							<div className="mt-2">
								<Button
									target="_blank"
									href="https://apps.apple.com/fr/app/radioplayer/id6443602613"
									className="appLink me-2"
									variant="link"
								>
									<img src={appStore} alt="" />
								</Button>
								<Button
									target="_blank"
									href="https://play.google.com/store/apps/details?id=com.radioplayer.mobile&pcampaignid=web_share"
									className="appLink"
									variant="link"
								>
									<img src={googlePlay} alt="" />
								</Button>
							</div>
						</Col>
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'first' },
							})}
						>
							<div className="tab-image" style={{ backgroundImage: `url(${watch})` }} />
						</Col>
					</Row>
					<Row className="tab-content" id="aboutab4">
						<Col md={6}>
							<div className="tab-image" style={{ backgroundImage: `url(${enceinte})` }} />
						</Col>
						<Col md={6}>
							<h3>{translate('Enceintes connectées')}</h3>
							<h5>{translate('Sonos')}</h5>
							<p>{translate('enceint_description')}</p>
						</Col>
					</Row>
					<Row className="tab-content" id="aboutab5">
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'last' },
							})}
						>
							<h3>{translate('Assistants vocaux')}</h3>
							<h5>{translate('Amazon Alexa')}</h5>
							<p>
								Commandez Radioplayer France à la voix sur votre enceinte connectée Amazon Alexa ou
								compatible. Retrouvez toutes les radios disponibles au sein de Radioplayer France à
								partir de votre enceinte connectée.
								<br />
								<br />
								Avec un <b>accès direct sur l'Assistant vocal Amazon.</b> Ainsi un auditeur n'aura qu'à
								dire : " Alexa lance RFM, RMC ou Alouette " pour pouvoir écouter sa radio préférée.
								<br />
								<br />
								N’hésitez pas, écoutez la radio dès maintenant à partir de la skill Radioplayer France.
							</p>
						</Col>
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'first' },
							})}
						>
							<div className="tab-image" style={{ backgroundImage: `url(${amazon})` }} />
						</Col>
					</Row>
					<Row className="tab-content" id="aboutab6">
						<Col md={6}>
							<div className="tab-image" style={{ backgroundImage: `url(${tv})` }} />
						</Col>
						<Col md={6}>
							<h3>{translate('Smart TV')}</h3>
							<h5>{translate('Android TV')}</h5>
							<p>{translate('smart_description')}</p>
						</Col>
					</Row>
					<Row className="tab-content" id="aboutab7">
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'last' },
							})}
						>
							<h3>{translate('Voitures')}</h3>
							<h5>{translate('Apple CarPlay, Android Auto, Android Automotive, SmartDeviceLink')}</h5>
							<p>{translate('car_description')}</p>
						</Col>
						<Col
							md={6}
							{...(width <= 765 && {
								xs: { span: 12, order: 'first' },
							})}
						>
							<div className="tab-image" style={{ backgroundImage: `url(${car})` }} />
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default Cms;
