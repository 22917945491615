import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputRange } from '../..';
import { setPlayer } from '../../../store/actions/player';

const Seek = ({ playerRef, duration }) => {
	const dispatch = useDispatch();
	const { played } = useSelector((state) => state.player);

	const handleSeekMouseDown = (e) => {
		dispatch(setPlayer({ seeking: true }));
	};

	const handleSeekChange = (e) => {
		dispatch(setPlayer({ played: parseFloat(e.target.value) }));
	};

	const handleSeekMouseUp = (e) => {
		let timeToGo = parseFloat(e.target.value) * playerRef.current.getDuration();
		playerRef.current.seekTo(timeToGo);
		dispatch(setPlayer({ seeking: false }));
	};
	return (
		<InputRange
			className="seek"
			type="range"
			min={0}
			max={0.999999}
			step="any"
			value={played}
			onMouseDown={handleSeekMouseDown}
			onChange={handleSeekChange}
			onInput={handleSeekChange}
			onMouseUp={handleSeekMouseUp}
		/>
	);
};

export default Seek;
