import React, { useEffect, useCallback, useState } from 'react';
import './style.css';
import RpLogo from '../../assets/images/square_rp_logo.png';
import { Link } from 'react-router-dom';
import { IoCloseOutline } from 'react-icons/io5';

const MobileBanner = ({ setIsBannerOpened }) => {
	const isClient = typeof window === 'object';
	const getSize = useCallback(() => {
		return isClient ? window.innerWidth : undefined;
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return false;
		}
		function handleResize() {
			setWindowSize(getSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize, getSize, isClient]);

	const storeUrl = 'https://app.adjust.com/1aucxkyv';

	return (
		<div className="mobileBanner">
			<div className="d-flex align-items-center w-100">
				<div className="me-1" onClick={() => setIsBannerOpened(false)}>
					<IoCloseOutline />
				</div>

				<img src={RpLogo} alt="Radioplayer" className="mobileBannerLogo" />
				<div className="d-flex align-items-center justify-content-between w-100">
					<div className="ms-2">
						<div className="d-flex flex-column justify-content-start">
							<div>Radioplayer, l'application</div>
							<div>À télécharger gratuitement </div>
						</div>
					</div>

					<Link className="mobileBannerBtn" to={storeUrl}>
						Installer
					</Link>
				</div>
			</div>
		</div>
	);
};

export default MobileBanner;
