import { SET_CATEGORIES, SET_COOKIES } from '../actions/constants';

const defaultState = {
	categories: [],
	cookies: false,
};
/*
[
    {
        href : string
        id : int
        name : string
        onboarding : string
        services : [int]
    },...
]
*/

export default function category(state = defaultState, action) {
	switch (action.type) {
		case SET_CATEGORIES:
			return { ...state, categories: action?.payload };
		case SET_COOKIES:
			return { ...state, cookies: action?.payload };

		default:
			return state;
	}
}
