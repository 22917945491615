import React, { useState, useCallback, useEffect } from 'react';
import { PodcastCategory } from '../../../components';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

function Categories({ categories }) {
	const [moreCategories, setMoreCategories] = useState(false);
	let fourcategories = categories.slice(0, 4);
	const isClient = typeof window === 'object';
	const getSize = useCallback(() => {
		return isClient ? window.innerWidth : undefined;
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return false;
		}
		function handleResize() {
			setWindowSize(getSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize, getSize, isClient]);
	const categorieToShow = windowSize <= 768.999 && !moreCategories ? fourcategories : categories;
	return (
		<div className="podcastCategory">
			{categorieToShow.map((item) => (
				<PodcastCategory key={item?.id?.toString()} item={item} />
			))}
			{windowSize <= 768.999 ? (
				!moreCategories ? (
					<div className="btn-show-categories" onClick={() => setMoreCategories(true)}>
						<button>
							Voir toutes les catégories <IoChevronDownOutline />
						</button>
					</div>
				) : (
					<div className="btn-show-categories" onClick={() => setMoreCategories(false)}>
						<button>
							Voir moins <IoChevronUpOutline />
						</button>
					</div>
				)
			) : null}
		</div>
	);
}

export default Categories;
