import moment from 'moment';

export const getBestStream = (streams, errored, lastUrl) => {
	if (streams?.length > 0) {
		if (errored) {
			let nextStreamQuality = false;
			for (let i = streams.length - 1; i >= 0; i--) {
				if (nextStreamQuality && streams[i].url) return streams[i].url;
				if (streams[i].url === lastUrl) {
					if (i === 0)
						return streams.find((stream) => stream.url !== lastUrl)
							? streams.find((stream) => stream.url !== lastUrl).url
							: streams.find((stream) => stream.url)
								? streams.find((stream) => stream.url).url
								: '';
					nextStreamQuality = true;
				}
			}
		} else {
			for (let i = streams.length - 1; i >= 0; i--) {
				if (streams[i].url) return streams[i].url;
			}
		}
	}
	return '';
};

export const getNextSevenDays = () => {
	let days = [];
	Array(7)
		.fill('')
		.forEach((el, index) => {
			days.push({
				label: moment().add(index, 'days').format('dddd'),
				value: moment().add(index, 'days').format('YYYY-MM-DD'),
			});
		});
	return days;
};
