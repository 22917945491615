import { SET_LOADING_SEARCH, SET_SUGGEST_RADIOS, SET_SUGGEST_PODCASTS } from './constants';
import apiInstance from '../../helpers/apiInstance';

export const searchRadios = (params) => {
	return (dispatch) => {
		dispatch({ type: SET_LOADING_SEARCH, payload: true });
		return apiInstance
			.get(`/radios`, { params })
			.then(({ data }) => {
				if (data.services) {
					dispatch({
						type: SET_SUGGEST_RADIOS,
						payload: data.services,
					});
				}
			})
			.catch((error) => {})
			.finally(() => {
				dispatch({ type: SET_LOADING_SEARCH, payload: false });
			});
	};
};

export const searchPodcasts = (params) => {
	return (dispatch) => {
		dispatch({ type: SET_LOADING_SEARCH, payload: true });
		return apiInstance
			.get(`/podcasts?name=${params.nom}`)
			.then(({ data }) => {
				if (data.podcasts) {
					dispatch({
						type: SET_SUGGEST_PODCASTS,
						payload: data.podcasts,
					});
				}
			})
			.catch((error) => {})
			.finally(() => {
				dispatch({ type: SET_LOADING_SEARCH, payload: false });
			});
	};
};
