import React from 'react';
import PodcastsTopSlider from '../../../components/PodcastsTopSlider';
import { useNavigate } from 'react-router-dom';
import { IoChevronForwardOutline } from 'react-icons/io5';
import translate from '../../../translation';

export default function Top({ podcasts, categories }) {
	const navigate = useNavigate();
	const onClick = (e) => {
		let catName = categories.find((categorie) => categorie.nom === e.target.dataset.id).nom?.replaceAll(' ', '_');
		navigate(`/podcasts-category/${catName}`);
	};
	return podcasts.map((podcast) => (
		<div className="carousel-container" key={podcast?.name?.toString()}>
			<h2 className="section2Title mt-5 ms-md-4" onClick={onClick} data-id={podcast?.name?.toString()}>
				{podcast.name}{' '}
				{podcast.name !== translate('Podcasts les + écoutés') && (
					<IoChevronForwardOutline data-id={podcast?.name?.toString()} />
				)}
			</h2>
			<PodcastsTopSlider podcasts={podcast?.series} />
		</div>
	));
}
