import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';
import { Helmet } from 'react-helmet';
import { scrollTop } from '../../helpers/behavior';

const Alexa = () => {
	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div id="footerPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta
					name="description"
					content="Retrouvez les radios françaises et les podcasts sur Radioplayer France. Plus de 800 radios et des milliers de podcasts à votre disposition."
				/>
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<div>
				<section className="section1">
					<Container>
						<h1>Radioplayer pour Amazon Alexa</h1>
					</Container>
				</section>
				<section className="section2">
					<Container>
						<h3>Dites simplement : Alexa ouvre Radioplayer</h3>
						<p>
							<strong>
								Les assistants vocaux sont très populaires et la radio est l’une des fonctions les plus
								utilisées. Mais parfois, il peut être difficile de trouver la bonne station de radio.
								Heureusement, nous vous proposons une nouvelle version de notre skill Radioplayer France
								pour enceintes Alexa.
							</strong>
						</p>
						<p>
							Par exemple, imaginons que vous souhaitez connaître la chanson en cours de diffusion ou que
							vous souhaitez être accompagné dans le choix d’une nouvelle station de radio, rendez-vous
							sur Radioplayer France grâce à votre assistant vocal Alexa.
						</p>
						<p>
							<strong>Alexa, ouvre Radioplayer</strong>
						</p>
						<p>
							Pour activer Radioplayer pour Alexa, recherchez «&nbsp;Radioplayer&nbsp;» dans la rubrique
							Skills et jeux de votre application Alexa ou demandez simplement à Alexa «
							<strong>&nbsp;ouvre Radioplayer&nbsp;</strong>».
						</p>
						<p>
							La première fois que vous utiliserez Radioplayer pour Alexa, vous devrez accepter certaines
							conditions afin que nous puissions vous offrir l’expérience radio la plus adaptée, mais
							sachez que nous ne recueillons aucune information personnelle permettant de vous identifier.
							Vos données sont entièrement anonymisées.
						</p>
						<p>
							Ensuite, à chaque fois que vous utiliserez Radioplayer pour Alexa, vous pourrez demander
							directement une station, en disant simplement «&nbsp;
							<strong>Alexa, demande à Radioplayer de diffuser la radio X</strong> (nom de la station que
							vous souhaitez écouter)&nbsp;». Si vous avez l’habitude d’écouter la même station, vous
							pouvez simplement dire «&nbsp;<strong>Alexa, ouvre Radioplayer</strong>&nbsp;», et
							Radioplayer reprendra la lecture de la dernière station écoutée.
						</p>
						<p>
							<strong>Principales fonctions et commandes</strong>
						</p>
						<p>
							Voici les commandes les plus courantes que vous pouvez utiliser avec Radioplayer pour Alexa
							:
						</p>
						<p>
							<strong>Demande à Radioplayer de lire Radio X </strong>(le nom de la station que vous
							souhaitez écouter).
						</p>
						<p>
							<strong>Demande à Radioplayer, qu’est-ce qui passe ? </strong>(Radioplayer vous indiquera le
							nom de la station, la chanson, l’artiste ou le programme que vous écoutez, en fonction de ce
							que la station actuelle nous indique)
						</p>
						<p>
							<strong>Demande à Radioplayer de jouer une station recommandée.</strong>
						</p>
						<p>
							<strong>Demande à Radioplayer de mettre une station locale</strong>
						</p>
						<p>
							<strong>Demande à Radioplayer d’écouter une station en vogue.</strong>
						</p>
						<p>
							<strong>Demande à Radioplayer de jouer une station aléatoire</strong>
						</p>
						<p>
							<strong>Demande à Radioplayer de mettre ma station la plus écoutée</strong>
						</p>
						<p>
							<strong>Demande à Radioplayer de lire ma dernière station écoutée</strong>
						</p>
						<p>
							<strong>Demande de l’aide à Radioplayer</strong>
						</p>
						<p>
							<strong>FAQ</strong>
						</p>
						<p>
							<strong>
								– Pourquoi Radioplayer pour Alexa me demande-t-il ma localisation / mon code postal ?
							</strong>
						</p>
						<p>
							Pour vous fournir de meilleures recommandations, Radioplayer vous demandera l’autorisation
							d’accéder à la localisation de votre appareil. La skill sera alors capable de vous proposer
							une station ou un décrochage local. Pour activer les autorisations de localisation, visitez
							l’application Radioplayer dans l’application Alexa. Dans la rubrique Paramètres, vous pouvez
							donner à l’application l’accès à votre code postal.
						</p>
						<p>
							<strong>– Comment puis-je supprimer mes données de Radioplayer pour Alexa ?</strong>
						</p>
						<p>Dites «&nbsp;Alexa, demande à Radioplayer de supprimer mes données&nbsp;».</p>
						<p>
							Radioplayer vous demandera alors de confirmer. Répondez par “Oui”. N’oubliez pas que cette
							opération ne peut pas être annulée.
						</p>
					</Container>
				</section>
			</div>
		</div>
	);
};

export default Alexa;
