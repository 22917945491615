import React from 'react';
import './style.css';
import { Modal, Button } from 'react-bootstrap';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	EmailShareButton,
} from 'react-share';
import { FiFacebook, FiLinkedin } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { RiTwitterLine, RiWhatsappLine } from 'react-icons/ri';

import translate from '../../translation';

/**
 * Affiche une modale contenant des liens de partage facebook, email, linkedIn, twitter, whatsapp
 *
 * @param {props} title
 * @param {props} shareUrl
 * @param {props} onHide
 * @returns JSX
 */
function ShareModal(props) {
	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-props.title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{translate('Partager avec vos amis')}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-around py-5">
				<FacebookShareButton url={props.shareurl} quote={props.title}>
					<FiFacebook size={30} color="#cf2345" />
				</FacebookShareButton>
				<EmailShareButton url={props.shareurl} subject={props.title}>
					<HiOutlineMail size={32} color="#cf2345" />
				</EmailShareButton>
				<LinkedinShareButton url={props.shareurl} title={props.title}>
					<FiLinkedin size={32} color="#cf2345" />
				</LinkedinShareButton>
				<TwitterShareButton url={props.shareurl} title={props.title}>
					<RiTwitterLine size={32} color="#cf2345" />
				</TwitterShareButton>

				<WhatsappShareButton url={props.shareurl} separator=":: " title={props.title}>
					<RiWhatsappLine size={32} color="#cf2345" />
				</WhatsappShareButton>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>{translate('Fermer')}</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ShareModal;
