import {
	PUSH_PODCAST,
	SET_TOP_PODCAST,
	SET_CATEGORIES_PODCAST,
	SET_SERIES_PODCAST,
	SET_GENRE_PODCAST,
	SET_CURRENT_PODCAST,
} from '../actions/constants';

const defaultState = {
	podcasts: [],
	podcastsTop: [],
	podcastsCategories: [],
	podcastsSeries: [],
	genrePodcasts: [],
	currentPodcast: {},
};

export default function podcasts(state = defaultState, action) {
	switch (action.type) {
		case PUSH_PODCAST:
			return { ...state, podcasts: action?.payload };
		case SET_TOP_PODCAST:
			return {
				...state,
				podcastsTop: action?.payload,
			};
		case SET_CATEGORIES_PODCAST:
			return { ...state, podcastsCategories: action?.payload };
		case SET_SERIES_PODCAST: {
			return { ...state, podcastsSeries: action?.payload };
		}
		case SET_GENRE_PODCAST: {
			return { ...state, genrePodcasts: action?.payload };
		}
		case SET_CURRENT_PODCAST: {
			return { ...state, currentPodcast: action?.payload };
		}
		default:
			return state;
	}
}
