import apiInstance from '../../helpers/apiInstance';
import { SET_CATEGORIES } from './constants';

export const getCategories = () => {
	return (dispatch) =>
		apiInstance
			.get('/categories/radios')
			.then(({ data }) => {
				if (data.categories) {
					dispatch({ type: SET_CATEGORIES, payload: data.categories });
				}
			})
			.catch((error) => {});
};
