import React from 'react';

const Share = ({ size, color }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size + 2} ${size + 2}`}>
			<g data-name="Layer 2">
				<g data-name="Share-up">
					<rect width={size} height={size} opacity="0" />
					<path
						fill={color}
						d="M20 11a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1z"
					/>
					<path
						fill={color}
						d="M16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L19 6.42V8a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2z"
					/>
				</g>
			</g>
		</svg>
	);
};
export default Share;
