//PLAYER
export const SET_PLAYER = 'SET_PLAYER';
export const SET_PLAYER_DATE = 'SET_PLAYER_DATE';

//RADIO
export const SET_FEATURED_RADIOS = 'SET_FEATURED_RADIOS';
export const SET_RADIOS = 'SET_RADIOS';
export const SET_RADIO = 'SET_RADIO';
export const SET_CURRENT_RADIO = 'SET_CURRENT_RADIO';
export const SET_SAME_PARENT_RADIOS = 'SET_SAME_PARENT_RADIOS';
export const SET_CURRENT_RADIOS_STREAMS = 'SET_CURRENT_RADIOS_STREAMS';
export const PUSH_RADIOS = 'PUSH_RADIOS';
export const SET_FEEDS_PODCASTS = 'SET_FEEDS_PODCASTS';
export const SET_EPISODES_PODCASTS = 'SET_EPISODES_PODCASTS';

//CATEGORY
export const SET_CATEGORIES = 'SET_CATEGORIES';

//FEED
export const SET_FEEDS = 'SET_FEEDS';
export const SET_CURRENT_FEED = 'SET_CURRENT_FEED';

//SEARCH
export const SET_LOADING_SEARCH = 'SET_LOADING_SEARCH';
export const SET_SUGGEST_RADIOS = 'SET_SUGGEST_RADIOS';
export const SET_SUGGEST_PODCASTS = 'SET_SUGGEST_PODCASTS';
export const SET_COOKIES = 'SET_COOKIES';

//PROGRAM
export const SET_RADIO_PROGRAM = 'SET_RADIO_PROGRAM';
export const SET_RADIO_PROGRAM_LOADING = 'SET_RADIO_PROGRAM_LOADING';

//PODCAST
export const PUSH_PODCAST = 'PUSH_PODCAST';
export const SET_SERIES_PODCAST = 'SET_SERIES_PODCAST';
export const SET_TOP_PODCAST = 'SET_TOP_PODCAST';
export const SET_ACTU_PODCAST = 'SET_TOP_PODCAST';
export const SET_DIV_PODCAST = 'SET_TOP_PODCAST';
export const SET_HUMOUR_PODCAST = 'SET_TOP_PODCAST';
export const SET_TV_PODCAST = 'SET_TOP_PODCAST';
export const SET_CATEGORIES_PODCAST = 'SET_CATEGORIES_PODCAST';
export const SET_GENRE_PODCAST = 'SET_GENRE_PODCAST';
export const SET_CURRENT_PODCAST = 'SET_CURRENT_PODCAST';
