import { SET_PLAYER } from '../actions/constants';

const defaultState = {
	played: 0,
	muted: false,
	volume: 0.8,
	loaded: 0,
	loadedSeconds: 0,
	playedSeconds: 0,
	seeking: false,
	playing: false,
	stream: {},
	isRadio: true,
	podcastId: '',
	pub: 0,
	radioStatePlay: 0,
	deltaTime: 0,
	userClick: false,
	lastRpidPlayed: 0,
	lastStartTime: 0,
};

export default function player(state = defaultState, action) {
	switch (action.type) {
		case SET_PLAYER:
			return { ...state, ...action?.payload };

		default:
			return state;
	}
}
