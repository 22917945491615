import { combineReducers } from 'redux';
import feed from './feed';
import category from './category';
import radio from './radio';
import podcasts from './podcasts';
import player from './player';
import search from './search';
import program from './program';

const rootReducer = combineReducers({
	feed,
	category,
	radio,
	podcasts,
	player,
	search,
	program,
});

export default rootReducer;
