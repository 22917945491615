import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { SearchIcon } from '../../assets/icons';
import translate from '../../translation';
import './style.css';
import debounce from 'lodash.debounce';
import { searchRadios, searchPodcasts } from '../../store/actions/search';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_SUGGEST_RADIOS, SET_SUGGEST_PODCASTS } from '../../store/actions/constants';
import Loading from '../Loading';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import Trackable from '../../rp_sdk/tracking_components/Trackable/trackable';
import Action from '../../rp_sdk/tracking_components/Action/action';

const containerRef = React.createRef();

const SearchBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { suggestions, suggestionsPodcasts, loading } = useSelector((state) => state.search);
	const [value, setValue] = useState('');
	const [moreRadios, setMoreRadios] = useState(false);
	const [morePodcasts, setMorePodcasts] = useState(false);
	const { innerWidth: width } = window;
	const maxCharacters = width > 1200 ? 60 : 26;
	let fiveSuggestions = suggestions.slice(0, 5);
	let fiveSuggestionsPodcasts = suggestionsPodcasts.slice(0, 5);

	const debouncedSave = debounce((newValue) => dispatch(searchRadios({ tag: newValue })), 500);
	const debouncedSavePc = debounce((newValue) => dispatch(searchPodcasts({ nom: newValue })), 500);

	useEffect(() => {
		const suggestionsElt = document.getElementById('suggestions');
		if (suggestionsElt)
			suggestionsElt.addEventListener('mousedown', (event) => {
				if (containerRef && !containerRef?.current?.contains(event?.target)) {
					dispatch({ type: SET_SUGGEST_RADIOS, payload: [] });
					dispatch({ type: SET_SUGGEST_PODCASTS, payload: [] });
				}
			});
	}, [dispatch]);

	const updateValue = (e) => {
		const newValue = e.target.value;
		setValue(newValue);
		if (newValue?.length >= 2) {
			debouncedSave(newValue);
			debouncedSavePc(newValue);
		}
		if (newValue?.length === 0) {
			dispatch({ type: SET_SUGGEST_RADIOS, payload: [] });
			dispatch({ type: SET_SUGGEST_PODCASTS, payload: [] });
			setValue('');
		}
	};

	const onClickItem = (item) => {
		navigate(`/radio/${item.rpID}-${encodeURI(item.nom?.trim()?.replace(/ /g, '-'))}`);
		dispatch({ type: SET_SUGGEST_RADIOS, payload: [] });
		dispatch({ type: SET_SUGGEST_PODCASTS, payload: [] });
	};

	const onClickItemPc = (item) => {
		navigate(`/podcast/${item.id}`);
		dispatch({ type: SET_SUGGEST_RADIOS, payload: [] });
		dispatch({ type: SET_SUGGEST_PODCASTS, payload: [] });
	};
	const radiosToShow = !moreRadios ? fiveSuggestions : suggestions;
	const podcastsToShow = !morePodcasts ? fiveSuggestionsPodcasts : suggestionsPodcasts;

	return (
		<div className="searchCon" ref={containerRef}>
			<div className="searchInputCon">
				<Trackable name="SearchTracking">
					<Action
						fire_ACTION_on={{ tag: 'input', event: 'onChange', id: 'search_input' }}
						subject={{ search: value }}
						type="search"
					/>
					<input
						id="search_input"
						className="searchInput"
						type="text"
						placeholder={translate('Recherchez une radio ou emission...')}
						value={value}
						onChange={updateValue}
					/>
				</Trackable>
				<Button variant="link" className="searchIcon">
					{loading ? <Loading size={18} /> : <SearchIcon size={22} color="#cf2345" />}
				</Button>
			</div>
			{(radiosToShow?.length > 0 || podcastsToShow?.length > 0) && value && (
				<div className="suggestCon" id="suggestions">
					{radiosToShow?.length > 0 ? (
						<div className="d-flex ms-4 font-weight-bolder">
							<p>RADIOS</p>
						</div>
					) : null}
					{radiosToShow.map((item) => (
						<div className="suggestItem" key={item.rpID.toString()} onClick={() => onClickItem(item)}>
							<div
								className="suggestImage"
								style={{
									backgroundImage: `url(${item.image?.url})`,
								}}
							/>
							<div>
								<h4 className="suggestName">{item.nom}</h4>
								<div className="desc">
									{item?.description?.substr(0, maxCharacters)}
									...
								</div>
							</div>
						</div>
					))}
					{radiosToShow?.length > 0 && fiveSuggestions.length < suggestions.length ? (
						!moreRadios ? (
							<div className="moreViews" onClick={() => setMoreRadios(true)}>
								{' '}
								Plus de radios <IoChevronForwardOutline />
							</div>
						) : (
							<div className="moreViews" onClick={() => setMoreRadios(false)}>
								{' '}
								Moins de radios <IoChevronBackOutline />
							</div>
						)
					) : null}

					<div className="d-flex ms-4 font-weight-bolder">
						<p>PODCASTS</p>
					</div>
					{podcastsToShow.map((item) => (
						<div className="suggestItem" key={item.id.toString()} onClick={() => onClickItemPc(item)}>
							<div
								className="suggestImage"
								style={{
									backgroundImage: `url(${item.image})`,
								}}
							/>
							<div>
								<h4 className="suggestName">{item.name}</h4>
							</div>
						</div>
					))}
					{podcastsToShow?.length > 0 && fiveSuggestionsPodcasts.length < suggestionsPodcasts.length ? (
						!morePodcasts ? (
							<div className="moreViews" onClick={() => setMorePodcasts(true)}>
								{' '}
								Plus de podcast <IoChevronForwardOutline />
							</div>
						) : (
							<div className="moreViews" onClick={() => setMorePodcasts(false)}>
								{' '}
								Moins de podcast <IoChevronBackOutline />
							</div>
						)
					) : null}
				</div>
			)}
		</div>
	);
};

export default SearchBar;
