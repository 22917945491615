import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SET_COOKIES } from '../../store/actions/constants';
import translate from '../../translation';
import './style.css';

const Footer = () => {
	const { innerWidth: width } = window;
	const dispatch = useDispatch();
	const { stream } = useSelector((state) => state.player);
	const { cookies } = useSelector((state) => state.category);
	const didomiRef = useRef(null);

	useEffect(() => {
		if (!cookies) {
			setTimeout(() => {
				didomiRef.current.click();
				dispatch({ type: SET_COOKIES, payload: true });
			}, 3000);
		}
	}, [cookies, dispatch]);

	return (
		<footer
			className="footer"
			style={{
				marginBottom: stream?.stream ? (width > 756 ? '86px' : '60px') : '0',
			}}
		>
			<Container>
				<Row>
					<Col md="4">
						<p>
							{new Date().getFullYear()} {translate('2020 Radioplayer France. Tous droits réservés')}
						</p>
					</Col>
					<Col md="8" className="d-flex justify-content-md-end">
						<Link className="footer-link" to="/contact">
							{translate('Contact')}
						</Link>
						<Link className="footer-link" to="/policy">
							{translate('Cookie')}
						</Link>
						<Link className="footer-link" to="/cgu">
							{translate('Radios')}
						</Link>
						<Link className="footer-link" to="/mentions">
							{translate('Vos données privées')}
						</Link>
						<div
							ref={didomiRef}
							className="footer-link"
							onClick={() => window.Didomi && window.Didomi.preferences.show()}
							style={{
								cursor: 'pointer',
							}}
						>
							{translate('Consentement')}
						</div>
						<Link className="footer-link" to="/radioplayer-pour-amazon-alexa">
							{translate('Alexa')}
						</Link>
						<Link className="footer-link" to="/radioplayer-pour-google-assistant">
							{translate('Google')}
						</Link>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
