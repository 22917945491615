import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';
import { Helmet } from 'react-helmet';
import { scrollTop } from '../../helpers/behavior';

const Cgu = () => {
	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div id="footerPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta
					name="description"
					content="Retrouvez les radios françaises et les podcasts sur Radioplayer France. Plus de 800 radios et des milliers de podcasts à votre disposition."
				/>
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<div>
				<section className="section1">
					<Container>
						<h1>CGU</h1>
					</Container>
				</section>
				<section className="section2">
					<Container>
						<h3>Conditions générales d’utilisation – Objet</h3>
						<p>
							Les présentes conditions générales d’utilisation (CGU) ont pour objet de régir l’accès et
							l’utilisation du présent site (ci-après, le «&nbsp;Site&nbsp;») édité par la société COSMOS,
							Société par Actions Simplifiée au capital de 90,000 Euros, immatriculée au Registre du
							Commerce et des Sociétés de Paris sous le numéro 888 335 759 et dont le siège social est
							situé 14 rue Lübeck 75016 à Paris (ci-après, «&nbsp;COSMOS&nbsp;»)
						</p>
						<p>
							Les présentes CGU prévalent sur toutes autres conditions générales ou particulières de
							l’utilisateur du Site (ci-après, «&nbsp;Vous&nbsp;», «&nbsp;Vos&nbsp;»,
							«&nbsp;Votre&nbsp;»).
						</p>
						<p>
							Tout accès au présent Site suppose Votre acceptation inconditionnelle et le respect de
							l’ensemble des termes des présentes CGU.&nbsp;Elles constituent le contrat entre COSMOS et
							Vous.
						</p>
						<p>
							Votre accès au Site signifie Votre acceptation des CGU. Dans le cas où Vous ne souhaitez pas
							accepter tout ou partie des présentes CGU, Vous renoncez à accéder aux services proposés par
							COSMOS via le Site.
						</p>
						<p>
							Le Site est accessible gratuitement en tout lieu à condition de disposer d’un accès à
							Internet. Tous les coûts relatifs à l’accès au Site, notamment les frais matériels,
							logiciels ou d’accès à internet, sont exclusivement à Votre charge.
						</p>
						<p>
							COSMOS se réserve le droit de modifier ou d’adapter les présentes CGU sans préavis en
							publiant une nouvelle version de ces dernières sur le Site. Ces modifications entreront en
							vigueur dès leur mise en ligne sur le Site et Vous seront donc opposables dès cet instant.
							COSMOS conseille à toute personne utilisant ou visitant le Site de sauvegarder ou d’imprimer
							les CGU, sans toutefois les modifier.
						</p>
						<h3>Propriété intellectuelle</h3>
						<p>
							Le Site, ainsi que les logiciels, structures des bases de données, textes, informations,
							analyses, images, photographies, graphismes, logos, sons ou toutes autres données contenues
							sur le Site demeurent la propriété exclusive de COSMOS ou, le cas échéant, de leurs
							propriétaires respectifs avec lesquels COSMOS a conclu des accords d’utilisation. Toute
							reproduction, utilisation ou représentation, totale ou partielle, de ce Site et/ou d’un de
							ses contenus par quelque procédé que ce soit, sans l’autorisation écrite ou expresse de
							COSMOS est interdite et est susceptible de constituer une contrefaçon sanctionnée par les
							articles L.335-2 et suivants du Code de la Propriété Intellectuelle.
						</p>
						<p>
							De même l’extraction et/ou la réutilisation des bases de données figurant sur le Site, dont
							COSMOS est productrice, est interdite au sens des articles L.342-1 et L.342-2 du Code de la
							propriété intellectuelle.
						</p>
						<p>
							COSMOS Vous accorde un droit d’utilisation, non exclusif et non transférable, du Site ainsi
							que des œuvres de l’esprit originales et données contenues sur le Site. Le droit ainsi
							concédé consiste en (i) un droit de consulter en ligne les œuvres de l’esprit originales et
							données contenues sur le Site. Ce droit d’utilisation s’entend uniquement pour un usage
							strictement privé. Tout autre droit est expressément réservé par COSMOS.
						</p>
						<p>
							Toute autre utilisation du Site, et notamment commerciale, de Votre part est interdite sans
							l’autorisation écrite et expresse de COSMOS.
						</p>
						<p>
							Vous Vous interdisez notamment, de manière non exhaustive, pour un usage autre que privé, de
							reproduire et/ou représenter, télécharger, vendre, distribuer, émettre, traduire, adapter,
							exploiter, distribuer, diffuser et communiquer intégralement ou partiellement sous quelque
							forme que ce soit, à titre commercial ou non, toute œuvre de l’esprit originale ou donnée
							contenue sur le Site.
						</p>
						<p>
							Vous Vous interdisez également d’introduire des données sur le Site qui modifieraient ou qui
							seraient susceptibles de modifier le contenu ou l’apparence des données, de la présentation
							ou de l’organisation du Site ou des œuvres figurant sur le Site, et par quelques procédés
							que ce soit.
						</p>
						<p>
							Les marques de COSMOS et de ses partenaires, ainsi que les logos figurant sur le site sont
							des marques déposées. Toute utilisation, représentation, reproduction totale ou partielle de
							ces marques et/ou de ces logos, effectuée à partir des éléments du site sans l’autorisation
							expresse de COSMOS et/ou des titulaires respectifs est donc prohibée, au sens de l’article
							L.713-2 du Code de la Propriété Intellectuelle.
						</p>
						<h3>Liens hypertextes</h3>
						<p>
							Le Site peut contenir des liens hypertextes vers d’autres sites présents sur le réseau
							Internet. Vous reconnaissez que les liens vers ces autres ressources sont susceptibles de
							Vous faire quitter le Site. COSMOS ne saurait être tenue responsable du contenu de ces
							sites. Tout lien hypertexte renvoyant au Site est formellement interdit sans autorisation
							expresse de COSMOS. COSMOS n’est pas responsable des liens hypertextes pointant vers le
							présent Site.
						</p>
						<h3>Données personnelles</h3>
						<p>
							Vous êtes informé que l’utilisation du Site donne lieu, pour COSMOS, à la collecte et au
							traitement automatisé de données à caractère personnel vous concernant, dont l’utilisation
							est soumise aux dispositions de la loi n°78-17 du 6 janvier 1978 relative à l’Informatique,
							aux Fichiers et aux Libertés telle que modifiée (ci-après, «&nbsp;Loi Informatique et
							Libertés&nbsp;») et du Règlement européen pour la protection des données n°2016/679 du 27
							avril 2016 (ci-après, «&nbsp;RGPD&nbsp;»).
						</p>
						<p>
							Les modalités de traitements de données à caractère personnel sont amplement détaillées dans
							la Politique de confidentialité disponible à l’adresse suivante&nbsp;:
							https://www.radioplayer.fr/privacy
						</p>
						<p>
							Conformément à la Loi Informatique et Libertés et au RGPD, Vous disposez d’un droit d’accès,
							de limitation, de rectification, de portabilité, d’opposition et de suppression des données
							à caractère personnel Vous concernant. Vous avez le droit de retirer, à tout moment, Votre
							consentement donné pour le traitement de Vos données basé sur ce fondement légal. Vous
							disposez enfin du droit de définir des directives définissant la manière dont Vous entendez
							que soient exercés, après Votre décès, ces droits.
						</p>
						<p>
							Vous pouvez exercer Vos droits en adressant un courrier à COSMOS SAS, 14 rue de Lübeck 75116
							Paris ou un e-mail à applications@cosmos.radio, et en joignant une copie recto-verso de
							Votre pièce d’identité, ou tout autre moyen permettant de justifier de Votre identité. Vous
							disposez du droit d’introduire une réclamation auprès de la Commission Nationale de
							l’Informatique et des Libertés (<a href="http://www.cnil.fr">www.cnil.fr</a>).
						</p>
						<h3>Responsabilité</h3>
						<p>
							COSMOS indique que le contenu du Site peut comporter des erreurs ou oublis, et qu’il est
							susceptible d’être modifié ou faire l’objet de mise à jour. Si Vous remarquez un lien brisé,
							une omission ou une erreur, Vous êtes invité à le signaler en envoyant un courriel au
							webmaster (applications@cosmos.radio).
						</p>
						<p>
							La responsabilité de COSMOS et/ou de ses partenaires ne pourra être engagée du fait de
							l’utilisation des informations, contenus fournis et/ou des outils, liens mis à disposition
							sur ce Site, et ce malgré les efforts mis en œuvre pour Vous fournir des informations et/ou
							contenus fiables et/ou des outils disponibles.
						</p>
						<p>
							COSMOS ne peut supporter aucune responsabilité pour d’autres faits que ceux relatifs à
							l’édition du Site. COSMOS ne peut garantir l’exhaustivité de son contenu ou l’absence de
							modification par un tiers (intrusion, virus).
						</p>
						<p>
							Le Site est accessible à tout moment, 24H sur 24H et 7 jours sur 7, sous réserve de la
							compatibilité et des performances de Votre matériel informatique et de Votre connexion
							Internet (fixe et/ou mobile).
						</p>
						<p>
							Vous êtes informé que vous devez disposer d’un matériel informatique (ordinateur, tablette,
							smartphone) compatible avec les dernières technologies HTML5, CSS.
						</p>
						<p>
							Votre attention est attirée sur le fait que l’accès au Site requiert une connexion
							permanente au réseau Internet (technologies full web responsive design).
						</p>
						<p>
							S’agissant d’une connexion Internet mobile, une connexion par la norme de technologie de
							téléphonie mobile de quatrième génération (4G) est très vivement recommandée.
						</p>
						<p>
							L’ensemble des frais afférents au matériel et connexions nécessaires à l’accès au Site
							demeurent à Votre charge exclusive.
						</p>
						<p>
							Vous avez conscience que l’accès au Site s’effectue via le réseau Internet. Vous êtes averti
							des aléas techniques qui peuvent affecter ce réseau et entraîner des ralentissements ou des
							indisponibilités rendant la connexion impossible.
						</p>
						<p>
							L’accès au Site peut en outre être momentanément interrompu ou limité pour des raisons de
							nécessité liées à l’amélioration de la qualité de services et notamment afin d’assurer la
							maintenance technique du Site. Dans la mesure du possible, COSMOS s’efforcera de Vous en
							avertir préalablement sur le Site.
						</p>
						<p>
							Vous ne pouvez rechercher la responsabilité de COSMOS au motif d’un gain manqué, d’une perte
							de chance ou de chiffre d’affaires.
						</p>
						<p>
							Vous déclarez accepter les caractéristiques et les limites d’internet, et notamment
							reconnaissez que les données circulant sur internet ne sont pas protégées contre les
							détournements éventuels. Modalités particulières d’inscription aux services par des
							mineurs&nbsp;: il est rappelé aux parents et à toute personne exerçant l’autorité parentale,
							qu’il leur appartient, d’une part, de déterminer quel(s) service(s) leur enfant mineur est
							autorisé à utiliser et, d’autre part, de surveiller l’utilisation que celui-ci fait de ce(s)
							service(s).
						</p>
						<h3>Divers</h3>
						<p>
							Dans l’hypothèse où l’un des termes des CGU serait considéré comme illégal ou inopposable
							par une décision de justice les autres stipulations resteraient valables. Les registres
							informatisés de COSMOS seront considérés par les parties comme preuve, notamment des
							communications intervenues entre les parties.
						</p>
						<h3>Litige et loi applicable</h3>
						<p>
							Le contrat est conclu en langue française. Les présentes CGU et toutes les situations qui en
							découlent sont soumises à la loi française. EN CAS DE LITIGE ENTRE LES PARTIES DECOULANT DE
							LA FORMATION, L’INTERPRETATION, L’EXECUTION ET/OU LA FIN DU CONTRAT, ET APRES UNE TENTATIVE
							DE RECHERCHE D’UNE SOLUTION AMIABLE, LA PARTIE DILIGENTE SAISIRA LE TRIBUNAL COMPETENT.
						</p>
					</Container>
				</section>
			</div>
		</div>
	);
};

export default Cgu;
