export const scrollTop = () => {
	const body = document.querySelector('body');
	if (body) {
		body.scrollIntoView({ behavior: 'smooth' });
	}
};

export const scrollToElement = (element) => {
	const el = document.querySelector(element);
	if (el) {
		el.scrollIntoView({ behavior: 'smooth' });
	}
};

export const scrollToOffset = (top) => {
	if (top) {
		window.scrollTo({
			top: top,
			behavior: 'smooth',
		});
	}
};
